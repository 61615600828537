export const TIP_CUSTOMER_ITEM_CREATE_STORE_NAME = "TIP_CREATE_ITEM_CUSTOMER";

export const TIP_ITEM_CREATE_CUSTOMER_API = {
  GET: {
    ENDPOINT: (purchaseId, type, price) =>
      `/payment/link/${type}/${purchaseId}?isTip=true${price ? `&price=${price}` : ""}`,
    TYPE: "GET",
  },
};

export const TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE = {
  FORM_PENDING: "TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_RESET",
};

export const TIP_ITEM_CREATE_CUSTOMER_DATA = {
  ID: "id",
};
