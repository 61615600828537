import { validate } from "../../main/validate";

import { GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "./gds-admin-item-update-data.constant";

import {
  requiredArray,
  arrayLengthMax,
  required,
  maxLength,
  numberPositive,
  url,
} from "../../main/validate/validate.service";

const getValidationConfig = (hasRu) => {
  const config = {
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_EN]: [arrayLengthMax(10)],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_EN]: [arrayLengthMax(1)],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_EN]: [maxLength(100000)],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]: [],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN]: [maxLength(100000)],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN]: [numberPositive],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: [],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TAG]: [],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN]: [maxLength(100)],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]: [],
    [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_EN]: [maxLength(50), url],
  };

  // Додаємо валідацію для полів RU лише якщо hasRu === true
  if (hasRu) {
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_RU] = [
      arrayLengthMax(10),
    ];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_RU] = [
      arrayLengthMax(2),
    ];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_RU] = [maxLength(100000)];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU] = [
      maxLength(100000),
    ];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU] = [numberPositive];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU] = [maxLength(100)];
    config[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_RU] = [maxLength(50), url];
  }

  return config;
};

export const gdsAdminItemUpdateDataFormValidation = (values) => {
  const hasRu = !!values[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.HAS_RU];
  const config = getValidationConfig(hasRu);
  return validate(values, config);
};
