import { FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH } from "../finder-chat-gds-item-admin-list/finder-chat-gds-item-admin-list.constant";

export const FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH = "/admin/finder/gds-item";

export const FINDER_TAB_LIST = [
  {
    id: 0,
    tid: "NAVIGATION.TAB.FINDER.GDS_ITEM_LIST",
    path: FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.FINDER.MESSAGE_LIST",
    path: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
  },
];

export const FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC = ({
  gdsId,
  gdsItemId,
}) => `/admin/gds/${gdsId}/item/${gdsItemId}`;

export const FINDER_GDS_ITEM_ADMIN_LIST_STORE_NAME =
  "FINDER_FINDER_GDS_ITEM_ADMIN_LIST";

export const FINDER_GDS_ITEM_ADMIN_LIST_API = {
  FINDER_GDS_ITEM_ADMIN_LIST: {
    ENDPOINT: () => `/gds/finder/admin/list`,
    TYPE: "GET",
  },
};

export const FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME = {
  ID: "id",
  ORDER: "order",
  STATUS: "status",
  BOUGHT: "bought",
  PRICE_RU: "priceRu",
  PRICE_EN: "priceEn",
  STOCK_RU: "stockRu",
  STOCK_EN: "stockEn",

  GDS: "gds",
  GDS_ID: "gdsId",

  LIST: "list",
  SKIP: "skip",
  TAKE: "take",
  BOUGHT: "bought",
  SORT_ORDER: "sortOrder",
  SORT_ID: "sortId",
  AMOUNT: "amount",
  SEARCH: "search",
};
