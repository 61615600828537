import React from "react";

import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import { TextComponent } from "../text";

export function NoticeItemAdminComponent({
  onClick,
  id,
  text,
  createDate,
  view,
  active,
  hour,
  type,
  user = { id: "N/A", login: "N/A" }, // Дефолтне значення,
}) {
  return (
    <ListItem button={!!onClick} variant="list" sx={{ py: 2 }}>
      <Grid container spacing={1}>
        <Grid item>
          <div>
            <TextComponent
              component="span"
              variant="body1"
              tid="NOTICE.ADMIN.LIST.ID"
              tvalue={{ id }}
            />
            {user ? ( // Перевірка наявності user
              <TextComponent
                component="span"
                variant="body1"
                tid="NOTICE.ADMIN.LIST.USER"
                tvalue={{ id: user.id, login: user.login }}
              />
            ) : (
              <TextComponent
                component="span"
                variant="body1"
                tid="NOTICE.ADMIN.LIST.USER"
                tvalue={{ id: "N/A", login: "N/A" }} // Дефолтні значення
              />
            )}
          </div>
        </Grid>
        <Grid item>
          <TextComponent
            variant="body1"
            tid="NOTICE.ADMIN.LIST.TYPE"
            tvalue={{ text, value: type.value }}
          />
        </Grid>
        <Grid item>
          <div>
            <TextComponent
              component="span"
              variant="body1"
              tid="NOTICE.ADMIN.LIST.CREATE_DATE"
              tvalue={{ createDate }}
            />
            {view ? (
              <TextComponent
                component="span"
                variant="body1"
                tid="NOTICE.ADMIN.LIST.VIEW_TRUE"
              />
            ) : (
              <TextComponent
                component="span"
                variant="body1"
                tid="NOTICE.ADMIN.LIST.VIEW_FALSE"
              />
            )}
          </div>
        </Grid>
        {!active ? (
          <Grid item>
            <div>
              <TextComponent
                component="span"
                variant="body1"
                tid="NOTICE.ADMIN.LIST.ACTIVE_FALSE"
                tvalue={{ active, hour }}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </ListItem>
  );
}
