import { httpRequest } from "../../main/http";

import {
  TIP_ADMIN_LIST_ACTION_TYPE,
  TIP_ADMIN_LIST_API,
} from "../tip-admin-list/tip-admin-list.constant";

import { convertTipAdminList } from "../tip-admin-list/tip-admin-list.convert";

import {
  TIP_ITEM_UPDATE_ADMIN_API,
  TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE,
} from "./tip-item-update-admin.constant";

export function uploadTipItemUpdateAdmin(data) {
  return async (dispatch) => {
    const { tipId } = data;

    dispatch({
      type: TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_PENDING,
      payload: tipId,
    });

    try {
      await httpRequest({
        method: TIP_ITEM_UPDATE_ADMIN_API.CHANGE_PASSWORD.TYPE,
        url: TIP_ITEM_UPDATE_ADMIN_API.CHANGE_PASSWORD.ENDPOINT(tipId),
        data,
      });

      dispatch({
        type: TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_SUCCESS,
        payload: tipId,
      });

      try {
        const res = await httpRequest({
          method: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.TYPE,
          url: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.ENDPOINT,
        });

        dispatch({
          type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
          data: convertTipAdminList(res.data),
        });
      } catch (error) {
        if (error.response) {
          dispatch({
            type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
            errorMessage: error.response.data.message,
          });
        }
      }
    } catch (error) {
      if (error.response) {
        dispatch({
          type: TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_ERROR,
          payload: { tipId, errorMessage: error.response.data.message },
        });
      }
    }
  };
}

export function resetTipAdminItemUpdateBoosterFormState(data) {
  const { tipId } = data;

  return async (dispatch) => {
    dispatch({
      type: TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_RESET,
      payload: tipId,
    });
  };
}
