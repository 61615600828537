import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TipCustomerItemCreateComponent } from "./tip-customer-item-create.component";
import { uploadTipItemCreateCustomer } from "./tip-customer-item-create.action";
import { TIP_CUSTOMER_ITEM_CREATE_STORE_NAME } from "./tip-customer-item-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function TipItemCreateUserContainer({ purchaseData }) {
  const dispatch = useDispatch();
  const [price, setPrice] = useState(1);

  const handleChangePrice = (e) => {
    const value = event.target.value;

    setPrice(value);
  };

  const { state, pageLoading } = useSelector((state) => ({
    state: state[TIP_CUSTOMER_ITEM_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const createTip = (type) => {
    dispatch(
      uploadTipItemCreateCustomer({
        purchaseId: purchaseData.id,
        type,
        price,
      })
    );
  };

  return (
    <TipCustomerItemCreateComponent
      price={price}
      handleChangePrice={handleChangePrice}
      purchaseData={purchaseData}
      createTip={createTip}
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
