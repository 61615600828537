import Box from "@mui/material/Box";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { AlertMessageElement } from "../../lib/common/alert-message";
import { ModalConfirmComponent } from "../../lib/common/modal-confirm/modal-confirm.component";

export function TipItemUpdateAdminComponent(props) {
  const { updateTip, isPending, isError, isSuccess, errorMessage, children } =
    props;


  return (
    <Box>
      <ModalConfirmComponent action={updateTip} disabled={isPending || isError}>
        {children}
      </ModalConfirmComponent>

      {isError && (
        <AlertMessageElement severity="error" tid={`ERROR.${errorMessage}`} />
      )}
      {isSuccess && (
        <AlertMessageElement
          severity="success"
          tid="TIP.ADMIN.UPDATE.ADMIN.SUCCESS_MESSAGE"
        />
      )}
      {isPending && <LoaderBarComponent />}
    </Box>
  );
}
