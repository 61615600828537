import { GDS_ITEM_ADMIN_LIST_DATA_NAME } from "./gds-item-admin-list.constant";

import { convertGdsItemStatus } from "../../lib/common/gds-item/gds-item.convert";

export const convertGdsItemAdminList = (d) => ({
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.LIST]: d.list.map((data) => {
    return {
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER]:
        data[GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER],
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]: String(
        data[GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]
      ),
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.ID]:
        data[GDS_ITEM_ADMIN_LIST_DATA_NAME.ID],
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS]:
        data[GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS],
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]: convertGdsItemStatus(
        data[GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]
      ),
    };
  }),
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]:
    d[GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS],
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]:
    d[GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT],
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]: d[GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP],
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.TAKE]: d[GDS_ITEM_ADMIN_LIST_DATA_NAME.TAKE],
  [GDS_ITEM_ADMIN_LIST_DATA_NAME.AMOUNT]:
    d[GDS_ITEM_ADMIN_LIST_DATA_NAME.AMOUNT],
});
