import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { SkeletonDataViewComponent } from "../../lib/common/skeleton-data-view/skeleton-data-view.component";
import { TextComponent } from "../../lib/common/text";

import { PurchaseAdminItemTimezoneViewComponent } from "./frame/purchase-admin-item-timezone-view.component";

export function PurchaseAdminItemTimezoneComponent(props) {
  const {
    pageLoading,
    isPending,
    onSubmitForm,
    isError,
    isSuccess,
    data,
    errorMessage,
    timezone,
  } = props;

  const isLoading = !data?.id && isPending;

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          {/*<Box sx={{ pb: 4 }}>*/}
          {/*  <TextComponent*/}
          {/*    variant="title"*/}
          {/*    component="div"*/}
          {/*    tid="PURCHASE.ADMIN.ITEM_TIMEZONE.TITLE"*/}
          {/*  />*/}
          {/*</Box>*/}
          {/*<Divider />*/}
          {!isLoading && (
            <PurchaseAdminItemTimezoneViewComponent
              timezone={timezone}
              data={data}
            />
          )}
          {isLoading && (
            // <Box sx={{ pt: 4 }}>
            <Box sx={{ pt: 0 }}>
              <SkeletonDataViewComponent />
            </Box>
          )}
          {isError && (
            // <Box sx={{ pt: 4 }}>
            <Box sx={{ pt: 0 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {/* {isPending && <LoaderBarComponent />} */}
        </Box>
      </Paper>
    </Box>
  );
}
