import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { TipAdminListContainer } from "./tip-admin-list.container";
import { TipAdminListPaginationContainer } from "../tip-admin-list-pagination/tip-admin-list-pagination.container";

export function TipAdminListPage() {
  return (
    <Container maxWidth="md">
      <Grid spacing={6} container>
        <Grid item>
          <TipAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <TipAdminListContainer />
        </Grid>
        <Grid item>
          <TipAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
