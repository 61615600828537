import { convertFileFieldValue } from "../../lib/common/field-file/field-file.convert";
import { convertSelectFieldValue } from "../../lib/common/field-select/field-select.convert";
import { GDS_ADMIN_CREATE_DATA_NAME } from "./gds-admin-create.constant";

export const convertGdsAdminCreateFormData = (data) => {
  const hasRu = !!data[GDS_ADMIN_CREATE_DATA_NAME.HAS_RU];

  const convertedData = {
    [GDS_ADMIN_CREATE_DATA_NAME.HAS_VERIFY]:
      !!data[GDS_ADMIN_CREATE_DATA_NAME.HAS_VERIFY],
    [GDS_ADMIN_CREATE_DATA_NAME.HAS_RU]:
      !!data[GDS_ADMIN_CREATE_DATA_NAME.HAS_RU],
    [GDS_ADMIN_CREATE_DATA_NAME.ORDER]: Number(
      data[GDS_ADMIN_CREATE_DATA_NAME.ORDER]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN]: Number(
      data[GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN]: Number(
      data[GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN]: String(
      data[GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]: String(
      data[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.BODY_EN]: String(
      data[GDS_ADMIN_CREATE_DATA_NAME.BODY_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.URL_EN]: String(
      data[GDS_ADMIN_CREATE_DATA_NAME.URL_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]: convertSelectFieldValue(
      data[GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.TYPE]: convertSelectFieldValue(
      data[GDS_ADMIN_CREATE_DATA_NAME.TYPE]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.TAG]: convertSelectFieldValue(
      data[GDS_ADMIN_CREATE_DATA_NAME.TAG]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN]: convertFileFieldValue(
      data[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN]
    ),
    [GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN]: convertFileFieldValue(
      data[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN]
    )[0],
  };

  // Додаємо поля для RU лише якщо hasRu === true
  if (hasRu) {
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU] = Number(
      data[GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.STOCK_RU] = Number(
      data[GDS_ADMIN_CREATE_DATA_NAME.STOCK_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU] = String(
      data[GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU] = String(
      data[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.BODY_RU] = String(
      data[GDS_ADMIN_CREATE_DATA_NAME.BODY_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.URL_RU] = String(
      data[GDS_ADMIN_CREATE_DATA_NAME.URL_RU]
    );
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU] =
      convertFileFieldValue(data[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU]);
    convertedData[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU] =
      convertFileFieldValue(
        data[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU]
      )[0];
  }

  return convertedData;
};
