export const GDS_ADMIN_CREATE_ROUTE_PATH = "/admin/gds/create";

export const GDS_ADMIN_CREATE_STORE_NAME = "GDS_ADMIN_CREATE";

export const GDS_ADMIN_CREATE_API = {
  GDS_ADMIN_CREATE: {
    ENDPOINT: "/gds/",
    TYPE: "POST",
  },
};

export const GDS_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ADMIN_CREATE_ACTION_TYPE.FORM_RESET",
};

export const GDS_ADMIN_CREATE_DATA_NAME = {
  ORDER: "order",
  PRICE_RU: "priceRu",
  STOCK_RU: "stockRu",
  PRICE_EN: "priceEn",
  STOCK_EN: "stockEn",
  TITLE_EN: "titleEn",
  TITLE_RU: "titleRu",
  DESCRIPTION_EN: "descriptionEn",
  DESCRIPTION_RU: "descriptionRu",
  BODY_EN: "bodyEn",
  BODY_RU: "bodyRu",
  URL_RU: "urlRu",
  URL_EN: "urlEn",
  FILE_PREVIEW_RU: "filePreviewImageRuId",
  FILE_PREVIEW_EN: "filePreviewImageEnId",
  FILE_LIST_EN: "fileImageListEnId",
  FILE_LIST_RU: "fileImageListRuId",
  CATEGORY: "category",
  TYPE: "type",
  TAG: "tag",
  HAS_VERIFY: "hasVerify",
  HAS_RU: "hasRu",
};
