import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

export function TabSkeletonComponent({ tab, value, handleChange }) {
  return (
    <Grid item>
      <Paper sx={{ py: 4, px: 6 }}>
        <Grid
          container
          spacing={6}
          sx={{ flexDirection: "row", justifyContent: "flex-start" }}
        >
          <Grid xs md={3} item>
            <Skeleton sx={{ width: "100%", height: "20px" }} />
          </Grid>
          <Grid xs md={3} item>
            <Skeleton sx={{ width: "100%", height: "20px" }} />
          </Grid>
          <Grid xs md={3} item>
            <Skeleton sx={{ width: "100%", height: "20px" }} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
