import React from "react";

import { UserAdminItemUpdateDataComponent } from "./user-admin-item-update-data.component";

import { useDispatch, useSelector } from "react-redux";

import { userAdminItemUpdateDataFormValidation } from "./user-admin-item-update-data.validation";

import { convertUserAdminItemUpdateDataFormData } from "./user-admin-item-update-data.convert";

import {
  uploadUserAdminItemUpdateDataForm,
  resetUserAdminItemUpdateDataFormState,
} from "./user-admin-item-update-data.action";

import {
  USER_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from "./user-admin-item-update-data.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { USER_ADMIN_ITEM_DATA_STORE_NAME } from "../user-admin-item-data/user-admin-item-data.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function UserAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, userItemStore } = useSelector((state) => ({
    state: state[USER_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    userItemStore: state[USER_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetUserAdminItemUpdateDataFormState());
  }, []);

  const userAdminItemUpdateDataFormSendData = (values) => {
    const data = convertUserAdminItemUpdateDataFormData(values);
    dispatch(uploadUserAdminItemUpdateDataForm(data, userItemStore.userId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(userItemStore.request)) {
      const data = getRequestData(userItemStore.request);
      return {
        [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD]: "",
        [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL]:
          data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL],
        [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE]:
          data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE],
        [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE]:
          data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE],
        [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE]:
          data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE],
      };
    }

    return {
      [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD]: "",
      [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL]: "",
      [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE]: "",
      [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE]: "",
      [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE]: "",
    };
  };

  return (
    <UserAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(userItemStore.request)}
      initialValue={getInitialValue()}
      validation={userAdminItemUpdateDataFormValidation}
      onSubmitForm={userAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
