import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";

import { GdsAdminItemUpdateDataFormComponent } from "./frame/gds-admin-item-update-data-form.component";

import { SkeletonFormComponent } from "../../lib/common/skeleton-form/skeleton-form.component";

export function GdsAdminItemUpdateDataComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    userEmail,
    isDependentPending,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="heading"
              gutterBottom
              component="div"
              tid="NEWS.ADMIN.ITEM_UPDATE_DATA.HEADING"
            />
            <TextComponent
              variant="subtitle"
              component="div"
              tid="NEWS.ADMIN.ITEM_UPDATE_DATA.SUBTITLE"
            />
          </Box>
          <Divider />
          {isDependentPending ? (
            <Box sx={{ py: 4 }}>
              <SkeletonFormComponent />
            </Box>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validate={validation}
              onSubmit={onSubmitForm}
            >
              {(props) => (
                <GdsAdminItemUpdateDataFormComponent
                  {...props}
                  isPending={isPending}
                  isError={isError}
                  errorMessage={errorMessage}
                  pageLoading={pageLoading}
                  userEmail={userEmail}
                />
              )}
            </Formik>
          )}

          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="GDS.ADMIN.ITEM_UPDATE_DATA.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
