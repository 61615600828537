import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { TextComponent } from "../../../lib/common/text/text.component";
import { GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-admin-item/gds-admin-item.constant";
import { GDS_ADMIN_LIST_DATA_NAME } from "../gds-admin-list.constant";
import { da } from "date-fns/locale";

export function GdsAdminListViewComponent(props) {
  const {
    data: { list: data },
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="GDS.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (gdsId) => (e) => {
    e.preventDefault();
    return navigate(GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(gdsId));
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item, itemIndex) => (
        <React.Fragment>
          <ListItem
            onClick={handleClickItem(item.id)}
            button
            variant="list"
            component="a"
            href={GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(item.id)}
          >
            <Grid sx={{ flexDirection: "row" }} container>
              <Grid item xs={12} md={2}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.ID"
                  tvalue={{
                    id: item[GDS_ADMIN_LIST_DATA_NAME.ID],
                  }}
                />
                <TextComponent
                  variant="body1"
                  tid="GDS.ADMIN.LIST.LIST.ORDER"
                  tvalue={{
                    order: item[GDS_ADMIN_LIST_DATA_NAME.ORDER],
                  }}
                />
              </Grid>

              <Grid item xs={12} md>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.TITLE_EN"
                  tvalue={{
                    titleEn: item[GDS_ADMIN_LIST_DATA_NAME.TITLE_EN],
                  }}
                />
                <TextComponent
                  variant="body1"
                  tid="GDS.ADMIN.LIST.LIST.URL_EN"
                  gutterBottom
                  tvalue={{
                    urlEn: item[GDS_ADMIN_LIST_DATA_NAME.URL_EN],
                  }}
                />
                {!!item[GDS_ADMIN_LIST_DATA_NAME.PRICE_RU] && (
                  <TextComponent
                    variant="body1"
                    gutterBottom
                    tid="GDS.ADMIN.LIST.LIST.TITLE_RU"
                    tvalue={{
                      titleRu: item[GDS_ADMIN_LIST_DATA_NAME.TITLE_RU],
                    }}
                  />
                )}
                {!!item[GDS_ADMIN_LIST_DATA_NAME.PRICE_RU] && (
                  <TextComponent
                    variant="body1"
                    tid="GDS.ADMIN.LIST.LIST.URL_RU"
                    tvalue={{
                      urlRu: item[GDS_ADMIN_LIST_DATA_NAME.URL_RU],
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.TYPE"
                  tvalue={{
                    type: item[GDS_ADMIN_LIST_DATA_NAME.TYPE].text,
                  }}
                />
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.STATUS"
                  tvalue={{
                    status: item[GDS_ADMIN_LIST_DATA_NAME.STATUS].text,
                  }}
                />
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.HAS_VERIFY"
                  tvalue={{
                    hasVerify: String(
                      item[GDS_ADMIN_LIST_DATA_NAME.HAS_VERIFY]
                    ),
                  }}
                />
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.CATEGORY"
                  tvalue={{
                    category: item[GDS_ADMIN_LIST_DATA_NAME.CATEGORY]
                      .map((i) => i.text)
                      .join(", "),
                  }}
                />
                <TextComponent
                  variant="body1"
                  tid="GDS.ADMIN.LIST.LIST.CREATE_DATE"
                  tvalue={{
                    createDate: item[GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA],
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextComponent
                  variant="body1"
                  gutterBottom
                  tid="GDS.ADMIN.LIST.LIST.PRICE_EN"
                  tvalue={{
                    priceEn: item[GDS_ADMIN_LIST_DATA_NAME.PRICE_EN],
                  }}
                />
                {/* {item[GDS_ADMIN_LIST_DATA_NAME.STOCK_EN] ? (
                  <TextComponent
                    variant="body1"
                    gutterBottom
                    tid="GDS.ADMIN.LIST.LIST.STOCK_EN"
                    tvalue={{
                      stockEn: item[GDS_ADMIN_LIST_DATA_NAME.STOCK_EN],
                    }}
                  />
                ) : (
                  <TextComponent
                    variant="body1"
                    gutterBottom
                    tid="GDS.ADMIN.LIST.LIST.STOCK_EN_NOT_SET"
                  />
                )} */}

                {!!item[GDS_ADMIN_LIST_DATA_NAME.PRICE_RU] && (
                  <TextComponent
                    variant="body1"
                    gutterBottom
                    tid="GDS.ADMIN.LIST.LIST.PRICE_RU"
                    tvalue={{
                      priceRu: item[GDS_ADMIN_LIST_DATA_NAME.PRICE_RU],
                    }}
                  />
                )}

                {/* {item[GDS_ADMIN_LIST_DATA_NAME.STOCK_RU] ? (
                  <TextComponent
                    variant="body1"
                    tid="GDS.ADMIN.LIST.LIST.STOCK_RU"
                    tvalue={{
                      stockRu: item[GDS_ADMIN_LIST_DATA_NAME.STOCK_RU],
                    }}
                  />
                ) : (
                  <TextComponent
                    variant="body1"
                    tid="GDS.ADMIN.LIST.LIST.STOCK_RU_NOT_SET"
                  />
                )} */}
              </Grid>
            </Grid>
          </ListItem>
          {itemIndex !== data.length - 1 && (
            <ListItem>
              <Divider sx={{ width: "100%", opacity: 0.35 }} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
