import { Alert } from "@mui/material";
import React, { useState } from "react";
import { TextComponent } from "../text";
import { AlertMessageElement } from "../alert-message";
import styled from "styled-components";

export const ButtonCopyComponent = ({ text, link }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const valueToCopy = link ? `=HYPERLINK("${link}";"${text}")` : text;

    navigator.clipboard
      .writeText(valueToCopy)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(() => {
        setIsCopied(false);
      });
  };

  return (
    <>
      {isCopied && (
        <AlertMessageElement
          tid="PURCHASE.COPY.COPIED_SUCCESS"
          severity="success"
        />
      )}

      <div>
        <Button onClick={handleCopy}>
          <TextComponent variant="copy" tid="PURCHASE.COPY.COPY" />
        </Button>
      </div>
    </>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0.12);
`;
