export const PAYMENT_VALUTE_TYPE = {
  DOLLAR: "USD",
  EURO: "EUR",
  RUBELS: "RUB",
  UAH: "UAH",
  KZT: "KZT",
  GBP: "GBP",
};

export const PAYMENT_SYSTEM_TYPE = {
  UNITPAY: "UNITPAY",
  PAYOP: "PAYOP",
  STRIPE: "STRIPE",
  COINBASE: "COINBASE",
  MANUAL: "MANUAL",
  ENOT: "ENOT",
  OPLATA: "OPLATA",
  FREEKASSA: "FREEKASSA",
  NOWPAYMENTS: "NOWPAYMENTS",
};

export const PAYMENT_VALUTE_VALUE = {
  USD: "DOLLAR",
  EUR: "EURO",
  RUB: "RUBELS",
  UAH: "UAH",
  KZT: "KZT",
  GBP: "GBP",
};

export const PAYMENT_VALUTE_ICON = {
  USD: "$",
  EUR: "€",
  RUB: "₽",
  UAH: "₴",
  KZT: "₸",
  GBP: "£",
};

export const PAYMENT_SYSTEM_VALUE = {
  UNITPAY: "UNITPAY",
  PAYOP: "PAYOP",
  STRIPE: "STRIPE",
  COINBASE: "COINBASE",
  MANUAL: "MANUAL",
  ENOT: "ENOT",
  OPLATA: "OPLATA",
  LAVA: "LAVA",
  PAYPALYCH: "PAYPALYCH",
  FREEKASSA: "FREEKASSA",
  NOWPAYMENTS: "NOWPAYMENTS",
};

export const PAYMENT_VALUTE_OPTION = [
  { id: 1, value: "USD", tid: "PAYMENT.DATA.VALUTE.DOLLAR" },
  { id: 2, value: "EUR", tid: "PAYMENT.DATA.VALUTE.EURO" },
  { id: 3, value: "RUB", tid: "PAYMENT.DATA.VALUTE.RUBELS" },
  { id: 4, value: "UAH", tid: "PAYMENT.DATA.VALUTE.UAH" },
  { id: 5, value: "KZT", tid: "PAYMENT.DATA.VALUTE.KZT" },
  { id: 6, value: "GBP", tid: "PAYMENT.DATA.VALUTE.GBP" },
];

export const PAYMENT_SYSTEM_OPTION = [
  { id: 1, value: "UNITPAY", tid: "PAYMENT.DATA.SYSTEM.UNITPAY" },
  { id: 2, value: "PAYOP", tid: "PAYMENT.DATA.SYSTEM.PAYOP" },
  { id: 3, value: "STRIPE", tid: "PAYMENT.DATA.SYSTEM.STRIPE" },
  { id: 4, value: "COINBASE", tid: "PAYMENT.DATA.SYSTEM.COINBASE" },
  { id: 5, value: "MANUAL", tid: "PAYMENT.DATA.SYSTEM.MANUAL" },
  { id: 6, value: "ENOT", tid: "PAYMENT.DATA.SYSTEM.ENOT" },
  { id: 7, value: "OPLATA", tid: "PAYMENT.DATA.SYSTEM.OPLATA" },
  { id: 8, value: "LAVA", tid: "PAYMENT.DATA.SYSTEM.LAVA" },
  { id: 9, value: "PAYPALYCH", tid: "PAYMENT.DATA.SYSTEM.PAYPALYCH" },
  { id: 10, value: "NOWPAYMENTS", tid: "PAYMENT.DATA.SYSTEM.NOWPAYMENTS" },
  { id: 11, value: "FREEKASSA", tid: "PAYMENT.DATA.SYSTEM.FREEKASSA" },
];
