import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TipAdminListComponent } from "./tip-admin-list.component";
import { getTipAdminList } from "./tip-admin-list.action";
import { TIP_ADMIN_LIST_STORE_NAME } from "./tip-admin-list.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";

export function TipAdminListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[TIP_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getTipAdminList());
  }, []);

  return (
    <TipAdminListComponent
      isPending={isRequestPending(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
