import Box from "@mui/material/Box";
import { useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { AlertMessageElement } from "../../lib/common/alert-message";
import { TextComponent } from "../../lib/common/text";
import { PaymentInfo } from "./frame/payment";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
};

export function TipCustomerItemCreateComponent(props) {
  const {
    createTip,
    isPending,
    isError,
    isSuccess,
    errorMessage,
    purchaseData,
    handleChangePrice,
    price,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  return (
    <Box>
      <TipButton
        onClick={() => {
          if (isPending) return;
          setOpen(true);
        }}
      >
        <TextComponent
          variant="label"
          component="span"
          tid="TIP.USER.CREATE.ADD_TIP"
        />
      </TipButton>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
      >
        <PaddingModal>
          <Box sx={{ ...style }}>
            <PaymentInfo
              price={price}
              handleChangePrice={handleChangePrice}
              createTip={createTip}
              data={purchaseData}
            />
          </Box>
        </PaddingModal>
      </Modal>
      {isError && (
        <AlertMessageElement severity="error" tid={`ERROR.${errorMessage}`} />
      )}
      {isSuccess && (
        <AlertMessageElement
          severity="success"
          tid="TIP.ADMIN.UPDATE.ADMIN.SUCCESS_MESSAGE"
        />
      )}
      {isPending && <LoaderBarComponent />}
    </Box>
  );
}

const PaddingModal = styled.div`
  padding: 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 440px;
  width: 100%;
  border-radius: 16px;
`;

const TipButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background: ${({ disabled }) =>
    disabled ? "rgba(255, 255, 255, 0.12)" : "#fff"};
  transition: opacity 0.3s ease;
  cursor: pointer;
  padding: 2px 12px;

  span {
    color: #5b25ec;
    font-size: 14px;
  }

  &:hover {
    opacity: ${({ disabled }) => (disabled ? "" : "0.7")};
  }
`;
