import { BOOST_TYPE_VALUE } from "../../../src/lib/common/boost/boost.type";

export const PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH = "/booster/order";

export const PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME =
  "PURCHASE_BOOSTER_ORDER_LIST";

export const PURCHASE_BOOSTER_ORDER_LIST_API = {
  PURCHASE_BOOSTER_ORDER_LIST: {
    ENDPOINT: `/purchase/booster/orders`,
    TYPE: "GET",
  },
};

export const PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME = {
  LIST: "list",

  ID: "id",
  TYPE: "type",
  CREATE_DATE: "createDate",
  STATUS: "status",
  PRICE: "price",
  VALUTE: "valute",
  LANG: "lang",
  METADATA: "metadata",
  NOTES: "notes",

  PAYMENT: "payment",
  PAYMENT_ID: "id",
  AMOUNT: "amount",
  STRIPE_PAID: "stripePaid",
  PAYOP_PAID: "payopPaid",
  STRIPE_CHECKED: "stripeChecked",
  ELOBOSS_GG: "elobossGg",
  COINBASE_PAID: "coinbasePaid",
  UNITPAY_PAID: "unitpayPaid",
  CONFIRM: "confirm",
  MANUAL_PAID: "manualPaid",
  SYSTEM: "system",

  SKIP: "skip",
  TAKE: "take",
  PAID: "paid",

  BOOSTER: "booster",
  BOOSTER_ID: "id",
  BOOSTER_EMAIL: "email",
  IS_BOOSTER: "isBooster",

  GDS_ITEM: "gdsItem",
  GDS_ITEM_ID: "id",
  GDS_ITEM_PRICE: "price",
  GDS_ITEM_STOCK: "stock",
  GDS_ITEM_TITLE: "titleEn",

  GDS: "gds",
  GDS_ID: "id",
  GDS_PRICE: "price",
  GDS_STOCK: "stock",
  GDS_TITLE: "titleEn",

  IS_PROMOCODE: "isPromocode",
  PROMOCODE: "promocode",
  PROMOCODE_ID: "id",
  PROMOCODE_NAME: "name",
  PROMOCODE_FACTOR: "factor",

  BOOST: "boost",
  BOOST_ID: "id",
  BOOST_DESIRED: "desired",
  BOOST_ACTUAL: "actual",
  BOOST_CURRENT: "current",
  BOOST_TYPE: "type",
  BOOST_PRICE: "price",
  BOOST_ADDITIONALS: "additionals",
  BOOST_DATA: "data",

  IS_CUSTOMER: "isCustomer",
  CUSTOMER: "customer",
  CUSTOMER_ID: "id",
  CUSTOMER_EMAIL: "email",

  COACHING: "coaching",
  COACHING_ADDITIONALS: "additionals",
  COACHING_ID: "id",
  COACHING_DATA: "data",
  COACHING_TYPE: "type",
  COACHING_SESSION_AMOUNT: "sessionAmount",
  COACHING_ACTUAL_SESSION: "actualSession",
};

export const ALLOWED_BOOST_TYPES = [
  BOOST_TYPE_VALUE.MARVEL_RIVALS_BOOST_TYPE_TROFIES,
  BOOST_TYPE_VALUE.MARVEL_RIVALS_BOOST_TYPE_WIN,
  BOOST_TYPE_VALUE.MARVEL_RIVALS_BOOST_TYPE_RANK,
];
