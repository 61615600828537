import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  GDS_ITEM_ADMIN_CREATE_DATA_NAME,
  GDS_ITEM_ADMIN_CREATE_SHORTCUT_LIST,
} from "../gds-item-admin-create.constant";

import { ChatMessageShortcutComponent } from "../../../lib/common/chat/chat-message-shortcut.component";

export const GdsItemAdminCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  const hanldePreviewChange = (v) => {
    setFieldValue(v);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          {/* <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.ORDER" />}
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER)}
              fullWidth
              errorText={getFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER)}
            />
          </Grid> */}
          <Grid item>
            <FieldTextComponent
              type="text"
              multiline
              label={<TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.DATA" />}
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA)}
              fullWidth
              errorText={getFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA)}
            />
          </Grid>
          <Grid item>
            <ChatMessageShortcutComponent
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA}
              onChange={handleChange}
              list={GDS_ITEM_ADMIN_CREATE_SHORTCUT_LIST}
            />
          </Grid>
          {/* <Grid item>
            <Divider />
          </Grid> */}
          {/* <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.PRICE_EN" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.STOCK_EN" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.TITLE_EN" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.DESCRIPTION_EN" />
              }
              multiline
              rows={1}
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]}
              error={isFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN
              )}
            />
          </Grid> */}
          {/* <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.PRICE_RU" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.STOCK_RU" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.TITLE_RU" />
              }
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU]}
              error={isFieldError(GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU)}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.DESCRIPTION_RU" />
              }
              multiline
              rows={1}
              name={GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU]}
              error={isFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU
              )}
              fullWidth
              errorText={getFieldError(
                GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU
              )}
            />
          </Grid> */}
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="GDS_ITEM.ADMIN.CREATE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
