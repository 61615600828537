import { validate } from "../../main/validate";

import { GDS_ADMIN_CREATE_DATA_NAME } from "./gds-admin-create.constant";

import {
  required,
  arrayLengthMax,
  requiredArray,
  maxLength,
  numberPositive,
  url,
} from "../../main/validate/validate.service";

const getValidationConfig = (hasRu) => {
  const config = {
    [GDS_ADMIN_CREATE_DATA_NAME.BODY_EN]: [required, maxLength(100000)],
    [GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]: [required],
    [GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]: [required, maxLength(100000)],
    [GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN]: [
      required,
      requiredArray,
      arrayLengthMax(10),
    ],
    [GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN]: [
      required,
      requiredArray,
      arrayLengthMax(1),
    ],
    [GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN]: [required, numberPositive],
    [GDS_ADMIN_CREATE_DATA_NAME.TAG]: [
      required,
      requiredArray,
      arrayLengthMax(5),
    ],
    [GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN]: [required, maxLength(100)],
    // [GDS_ADMIN_CREATE_DATA_NAME.TYPE]: [required],
    [GDS_ADMIN_CREATE_DATA_NAME.URL_EN]: [required, maxLength(50), url],
  };

  // Додаємо валідацію для полів RU лише якщо hasRu === true
  if (hasRu) {
    config[GDS_ADMIN_CREATE_DATA_NAME.BODY_RU] = [required, maxLength(100000)];
    config[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU] = [
      required,
      maxLength(100000),
    ];
    config[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU] = [
      required,
      requiredArray,
      arrayLengthMax(10),
    ];
    config[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU] = [
      required,
      requiredArray,
      arrayLengthMax(1),
    ];
    config[GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU] = [required, numberPositive];
    config[GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU] = [required, maxLength(100)];
    config[GDS_ADMIN_CREATE_DATA_NAME.URL_RU] = [required, maxLength(50), url];
  }

  return config;
};

export const gdsAdminCreateFormValidation = (values) => {
  const hasRu = !!values[GDS_ADMIN_CREATE_DATA_NAME.HAS_RU];
  const config = getValidationConfig(hasRu);
  return validate(values, config);
};
