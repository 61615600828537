import React from "react";
import styled from "styled-components";

import { TextComponent } from "../../lib/common/text";

export function NavigationOnlineComponent({ isOnline }) {
  return (
    <TextComponentContainer>
      <TextComponent
        tid={!isOnline ? "NAVIGATION.OFFLINE" : "NAVIGATION.ONLINE"}
      />
    </TextComponentContainer>
  );
}

const TextComponentContainer = styled.div`
  min-width: 45px;
`;
