import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { TextComponent } from "../../../lib/common/text/text.component";
import { CardViewComponent } from "./card-view.component";
import { FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC } from "../finder-chat-gds-item-admin-list.constant";
import { ListItem } from "@mui/material";

export function FinderChatAdminListViewComponent(props) {
  const {
    data: { list: data },
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="FINDER.ADMIN.LIST.CHAT.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => () => {
    navigate(
      FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC({
        purchaseId,
      })
    );
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem
          onClick={handleClickItem(item.purchase.id)}
          button
          variant="list"
        >
          <CardViewComponent data={item} />
        </ListItem>
      ))}
    </List>
  );
}
