import { FILE_DATA_TYPE } from "./file.type";

export const convertFile = (data) => {
  if (!data) {
    return null;
  }

  return {
    [FILE_DATA_TYPE.ID]: data[FILE_DATA_TYPE.ID],
    [FILE_DATA_TYPE.NAME]: data[FILE_DATA_TYPE.NAME],
    [FILE_DATA_TYPE.URL]: data[FILE_DATA_TYPE.URL],
  };
};

export const convertFileList = (list) => list.map(convertFile);
