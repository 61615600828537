import { id } from "date-fns/locale";

export const GDS_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const GDS_TYPE_TYPE = {
  CLASSIC: 1,
  ITEM: 2,
  MANUAL: 3,
};

export const GDS_TAG_TYPE = {
  SALE: 1,
  NEW: 2,
  BEST_SELLER: 3,
  INSTANT_DELIVERY: 4,
  MANUAL_DELIVARY: 5,
};

export const GDS_CATEGORY_TYPE = {
  FACEIT_10_LVL_PLUS: 1,
  FACEIT_4_9_LVL: 2,
  FACEIT_READY: 3,
  CSGO_RANKED: 4,
  STEAM_WITH_HOURS: 5,
  ESEA_RANKED: 6,
  FACEIT_1_LVL: 7,
  CS2: 8,
  MARVEL_RIVALS: 9,
  GTA6: 10,
  BUY_CS2_RANK_PREMIER_READY: 11,
  BUY_CS2_RANK_PREMIER_GREY: 12,
  BUY_CS2_RANK_PREMIER_GOLD: 13,
  BUY_CS2_RANK_PREMIER_CYAN: 14,
  BUY_CS2_RANK_PREMIER_PURPLE: 15,
  BUY_CS2_RANK_PREMIER_MM_RANKED: 16,
  BUY_MARVEL_RIVALS_RANKED_READY: 30,
  BUY_MARVEL_RIVALS_RANK_BRONZE: 17,
  BUY_MARVEL_RIVALS_RANK_SILVER: 18,
  BUY_MARVEL_RIVALS_RANK_GOLD: 19,
  BUY_MARVEL_RIVALS_RANK_PLATINUM: 20,
  BUY_MARVEL_RIVALS_RANK_DIAMOND: 21,
  BUY_MARVEL_RIVALS_RANK_GRANDMASTER: 22,
  BUY_MARVEL_RIVALS_RANK_ETERNITY: 23,
  BUY_MARVEL_RIVALS_RANK_ONE_ABOVE_ALL: 24,
  BUY_GTA6_ACCOUNT_MONEY: 25,
  BUY_GTA6_ACCOUNT_RP: 26,
  BUY_GTA6_ACCOUNT_CARS: 27,
  BUY_CS2_RANK_PREMIER_BLUE: 31,
  BUY_CS2_RANK_PREMIER_PINK: 32,
};

export const GDS_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const GDS_TAG_VALUE = {
  1: "SALE",
  2: "NEW",
  3: "BEST_SELLER",
  4: "INSTANT_DELIVERY",
  5: "MANUAL_DELIVARY",
};

export const GDS_STATUS_VALUE = {
  1: "ACTIVE",
  2: "DISABLED",
};

export const GDS_TYPE_VALUE = {
  1: "CLASSIC",
  2: "ITEM",
  3: "MANUAL",
};

export const GDS_CATEGORY_VALUE = {
  1: "FACEIT_10_LVL_PLUS",
  2: "FACEIT_4_9_LVL",
  3: "FACEIT_READY",
  4: "CSGO_RANKED",
  5: "STEAM_WITH_HOURS",
  6: "ESEA_RANKED",
  7: "FACEIT_1_LVL",
  8: "CS2",
  9: "MARVEL_RIVALS",
  10: "GTA6",
  11: "BUY_CS2_RANK_PREMIER_READY",
  12: "BUY_CS2_RANK_PREMIER_GREY",
  13: "BUY_CS2_RANK_PREMIER_GOLD",
  14: "BUY_CS2_RANK_PREMIER_CYAN",
  15: "BUY_CS2_RANK_PREMIER_PURPLE",
  16: "BUY_CS2_RANK_PREMIER_MM_RANKED",
  30: "BUY_MARVEL_RIVALS_RANKED_READY",
  17: "BUY_MARVEL_RIVALS_RANK_BRONZE",
  18: "BUY_MARVEL_RIVALS_RANK_SILVER",
  19: "BUY_MARVEL_RIVALS_RANK_GOLD",
  20: "BUY_MARVEL_RIVALS_RANK_PLATINUM",
  21: "BUY_MARVEL_RIVALS_RANK_DIAMOND",
  22: "BUY_MARVEL_RIVALS_RANK_GRANDMASTER",
  23: "BUY_MARVEL_RIVALS_RANK_ETERNITY",
  24: "BUY_MARVEL_RIVALS_RANK_ONE_ABOVE_ALL",
  25: "BUY_GTA6_ACCOUNT_MONEY",
  26: "BUY_GTA6_ACCOUNT_RP",
  27: "BUY_GTA6_ACCOUNT_CARS",
  28: "BUY_CS2_RANK_PREMIER_RED",
  29: "BUY_MARVEL_RIVALS_RANK_CALESTIAL",
  31: "BUY_CS2_RANK_PREMIER_BLUE",
  32: "BUY_CS2_RANK_PREMIER_PINK",
};

export const GDS_SORT_VALUE = {
  1: "ORDER",
  2: "ID",
};

export const GDS_STATUS_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.STATUS.ACTIVE" },
  { id: 2, value: 2, tid: "GDS.DATA.STATUS.DISABLED" },
];

export const GDS_TYPE_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.TYPE.CLASSIC" },
  { id: 2, value: 2, tid: "GDS.DATA.TYPE.ITEM" },
  { id: 3, value: 3, tid: "GDS.DATA.TYPE.MANUAL" },
];

export const GDS_HAS_RU_OPTION = [
  { id: true, value: true, tid: "GDS.DATA.HAS_RU.TRUE" },
  { id: false, value: false, tid: "GDS.DATA.HAS_RU.FALSE" },
];

export const GDS_TAG_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.TAG.SALE" },
  { id: 2, value: 2, tid: "GDS.DATA.TAG.NEW" },
  { id: 3, value: 3, tid: "GDS.DATA.TAG.BEST_SELLER" },
  { id: 4, value: 4, tid: "GDS.DATA.TAG.INSTANT_DELIVERY" },
  { id: 5, value: 5, tid: "GDS.DATA.TAG.MANUAL_DELIVARY" },
];

export const GDS_CATEGORY_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.CATEGORY.FACEIT_10_LVL_PLUS" },
  { id: 2, value: 2, tid: "GDS.DATA.CATEGORY.FACEIT_4_9_LVL" },
  { id: 3, value: 3, tid: "GDS.DATA.CATEGORY.FACEIT_READY" },
  { id: 4, value: 4, tid: "GDS.DATA.CATEGORY.CSGO_RANKED" },
  { id: 5, value: 5, tid: "GDS.DATA.CATEGORY.STEAM_WITH_HOURS" },
  { id: 6, value: 6, tid: "GDS.DATA.CATEGORY.ESEA_RANKED" },
  { id: 7, value: 7, tid: "GDS.DATA.CATEGORY.FACEIT_1_LVL" },
  { id: 8, value: 8, tid: "GDS.DATA.CATEGORY.CS2" },
  { id: 9, value: 9, tid: "GDS.DATA.CATEGORY.MARVEL_RIVALS" },
  { id: 10, value: 10, tid: "GDS.DATA.CATEGORY.GTA6" },
];

export const GDS_CATEGORY_GROUPED_OPTION = [
  {
    id: 1,
    label: "GDS.DATA.CATEGORY.BUY_FACEIT",
    items: [
      { id: 7, value: 7, tid: "GDS.DATA.CATEGORY.FACEIT_1_LVL" },
      { id: 2, value: 2, tid: "GDS.DATA.CATEGORY.FACEIT_4_9_LVL" },
      { id: 1, value: 1, tid: "GDS.DATA.CATEGORY.FACEIT_10_LVL_PLUS" },
      { id: 3, value: 3, tid: "GDS.DATA.CATEGORY.FACEIT_READY" },
      { id: 5, value: 5, tid: "GDS.DATA.CATEGORY.STEAM_WITH_HOURS" },
      { id: 6, value: 6, tid: "GDS.DATA.CATEGORY.ESEA_RANKED" },
    ],
  },
  // {
  //   id: 2,
  //   label: "GDS.DATA.CATEGORY.CS_GO",
  //   items: [{ id: 4, value: 4, tid: "GDS.DATA.CATEGORY.CSGO_RANKED" },
  //    { id: 8, value: 8, tid: "GDS.DATA.CATEGORY.CS2" },
  //
  // ],
  // },
  {
    id: 2,
    label: "GDS.DATA.CATEGORY.CS2",
    items: [
      {
        id: 11,
        value: 11,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_READY",
      },
      {
        id: 12,
        value: 12,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_GREY",
      },
      {
        id: 14,
        value: 14,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_CYAN",
      },
      {
        id: 13,
        value: 13,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_GOLD",
      },
      {
        id: 15,
        value: 15,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_PURPLE",
      },
      {
        id: 28,
        value: 28,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_RED",
      },
      {
        id: 16,
        value: 16,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_MM_RANKED",
      },
      {
        id: 31,
        value: 31,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_BLUE",
      },
      {
        id: 32,
        value: 32,
        tid: "GDS.DATA.CATEGORY.BUY_CS2_RANK_PREMIER_PINK",
      },
    ],
  },
  {
    id: 3,
    label: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVAL",
    items: [
      // { id: 9, value: 9, tid: "GDS.DATA.CATEGORY.MARVEL_RIVALS" },
      {
        id: 30,
        value: 30,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANKED_READY",
      },
      {
        id: 17,
        value: 17,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_BRONZE",
      },
      {
        id: 18,
        value: 18,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_SILVER",
      },
      {
        id: 19,
        value: 19,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_GOLD",
      },
      {
        id: 20,
        value: 20,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_PLATINUM",
      },
      {
        id: 21,
        value: 21,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_DIAMOND",
      },
      {
        id: 22,
        value: 22,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_GRANDMASTER",
      },
      {
        id: 29,
        value: 29,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_CALESTIAL",
      },
      {
        id: 23,
        value: 23,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_ETERNITY",
      },
      {
        id: 24,
        value: 24,
        tid: "GDS.DATA.CATEGORY.BUY_MARVEL_RIVALS_RANK_ONE_ABOVE_ALL",
      },
    ],
  },
  {
    id: 4,
    label: "GDS.DATA.CATEGORY.GTA6",
    items: [
      // { id: 10, value: 10, tid: "GDS.DATA.CATEGORY.GTA6" },
      { id: 25, value: 25, tid: "GDS.DATA.CATEGORY.BUY_GTA6_ACCOUNT_MONEY" },
      { id: 26, value: 26, tid: "GDS.DATA.CATEGORY.BUY_GTA6_ACCOUNT_RP" },
      { id: 27, value: 27, tid: "GDS.DATA.CATEGORY.BUY_GTA6_ACCOUNT_CARS" },
    ],
  },
];

export const GDS_SORT_OPTION = [
  { id: 1, value: 1, tid: "GDS.DATA.SORT.ORDER" },
  { id: 2, value: 2, tid: "GDS.DATA.SORT.ID" },
];
