import { httpRequest } from "../../main/http";

import {
  TIP_ADMIN_LIST_API,
  TIP_ADMIN_LIST_ACTION_TYPE,
  TIP_ADMIN_LIST_DATA_NAME,
  TIP_ADMIN_LIST_STORE_NAME,
} from "./tip-admin-list.constant";

import { convertTipAdminList } from "./tip-admin-list.convert";
import {
  getRequestData,
  isRequestPending,
  isRequestUpdatePending,
} from "../../main/store/store.service";

export function getTipAdminList() {
  return async (dispatch, getState) => {
    const stateData = getRequestData(
      getState()[TIP_ADMIN_LIST_STORE_NAME].request
    );

    dispatch({
      type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.TYPE,
        url: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.ENDPOINT,
      });

      dispatch({
        type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertTipAdminList(res.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updateTipAdminList(data) {
  return async (dispatch, getStateF) => {
    const getState = getStateF();

    const stateIsPending = isRequestPending(
      getState[TIP_ADMIN_LIST_STORE_NAME].request
    );

    const stateIsUpdatePending = isRequestUpdatePending(
      getState[TIP_ADMIN_LIST_STORE_NAME].request
    );

    if (stateIsPending || stateIsUpdatePending) return null;

    const stateData = getRequestData(
      getState[TIP_ADMIN_LIST_STORE_NAME].request
    );

    const params = data
      ? {
          [TIP_ADMIN_LIST_DATA_NAME.STATUS]:
            data[TIP_ADMIN_LIST_DATA_NAME.STATUS],
          [TIP_ADMIN_LIST_DATA_NAME.PAID]: data[TIP_ADMIN_LIST_DATA_NAME.PAID],
          [TIP_ADMIN_LIST_DATA_NAME.SKIP]: data[TIP_ADMIN_LIST_DATA_NAME.SKIP],
          [TIP_ADMIN_LIST_DATA_NAME.TYPE]: data[TIP_ADMIN_LIST_DATA_NAME.TYPE],
        }
      : {
          [TIP_ADMIN_LIST_DATA_NAME.STATUS]:
            stateData[TIP_ADMIN_LIST_DATA_NAME.STATUS],
          [TIP_ADMIN_LIST_DATA_NAME.PAID]:
            stateData[TIP_ADMIN_LIST_DATA_NAME.PAID],
          [TIP_ADMIN_LIST_DATA_NAME.SKIP]:
            stateData[TIP_ADMIN_LIST_DATA_NAME.SKIP],
          [TIP_ADMIN_LIST_DATA_NAME.TYPE]:
            stateData[TIP_ADMIN_LIST_DATA_NAME.TYPE],
        };

    dispatch({
      type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.TYPE,
        url: TIP_ADMIN_LIST_API.TIP_ADMIN_LIST.ENDPOINT,
        params,
      });

      dispatch({
        type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertTipAdminList(res.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
