import React from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { TextComponent } from "../../lib/common/text";
import Grid from "@mui/material/Grid";

import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";

import {
  GDS_CATEGORY_OPTION,
  GDS_STATUS_OPTION,
  GDS_SORT_OPTION,
  GDS_SORT_TYPE,
  GDS_TYPE_OPTION,
  GDS_CATEGORY_GROUPED_OPTION,
  GDS_HAS_RU_OPTION,
} from "../../lib/common/gds/gds.type";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

import { FieldSelectComponent } from "../../lib/common/field-select/field-select.component";

import { GDS_ADMIN_LIST_DATA_NAME } from "../gds-admin-list/gds-admin-list.constant";
import {
  convertGdsCategoryList,
  convertGdsStatus,
  convertGdsType,
} from "../../lib/common/gds/gds.convert";
import { FieldSelectGroupComponent } from "../../lib/common/field-select-group/field-select-group.component";

export function GdsAdminListPaginationComponent(props) {
  const { onChangeFilter, data } = props;

  const [openFilter, setOpenFilter] = React.useState();

  const handleOpenFilter = () => {
    if (openFilter === true) {
      onChangeFilter({
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
        [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: false,
        [GDS_ADMIN_LIST_DATA_NAME.HAS_RU]: false,
        [GDS_ADMIN_LIST_DATA_NAME.STATUS]: null,
      });
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeFilterSelect = (key) => (e) => {
    const value = e?.target?.value;

    // Якщо value - масив
    if (Array.isArray(value)) {
      const ids = value
        .map((item) => (typeof item === "object" ? item.id : item)) // Отримуємо `id` з об'єкта
        .filter(Boolean); // Фільтруємо невалідні значення

      if (ids.length > 0) {
        const categoryString = ids.join(","); // Перетворюємо масив у рядок через кому
        onChangeFilter({ [key]: categoryString }); // Передаємо рядок
        console.log("Фільтр по категоріях (рядок):", categoryString);
      } else {
        console.error("Невалідний масив категорій:", value);
      }
    }
    // Якщо value - одиничне значення
    else if (value?.id) {
      const categoryString = value.id.toString(); // Перетворюємо одиничне значення у рядок
      onChangeFilter({ [key]: categoryString }); // Передаємо рядок
      console.log("Фільтр по категорії (одинична):", categoryString);
    } else {
      console.error("Невалідне значення категорій:", value);
    }
  };

  const handleChangeSortType = (e) => {
    const v = e.target.value.value;

    if (v === GDS_SORT_TYPE.ID) {
      onChangeFilter({
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]: true,
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
      });
    }

    if (v === GDS_SORT_TYPE.ORDER) {
      onChangeFilter({
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: true,
      });
    }
  };

  const handleChangeSortHasRu = (event) => {
    onChangeFilter({
      [GDS_ADMIN_LIST_DATA_NAME.HAS_RU]: event.target.checked,
    });
  };

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [GDS_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid container spacing={4}>
          <Grid item>
            <FieldSelectGroupComponent
              multiple={true}
              onChange={handleChangeFilterSelect(
                GDS_ADMIN_LIST_DATA_NAME.CATEGORY
              )}
              value={convertGdsCategoryList(
                data[GDS_ADMIN_LIST_DATA_NAME.CATEGORY]
              )}
              option={GDS_CATEGORY_GROUPED_OPTION}
              label={<TextComponent tid="GDS.ADMIN.PAGINATION.CATEGORY" />}
            />
          </Grid>
          <Grid item>
            <Paper>
              <FormGroup>
                <FormControlLabel
                  sx={{ py: 1, px: 2, m: 0 }}
                  control={<Switch />}
                  onChange={handleOpenFilter}
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.FILTER" />}
                />
              </FormGroup>
            </Paper>
          </Grid>
          {openFilter && (
            <React.Fragment>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    GDS_ADMIN_LIST_DATA_NAME.STATUS
                  )}
                  value={convertGdsStatus(
                    data[GDS_ADMIN_LIST_DATA_NAME.STATUS]
                  )}
                  option={GDS_STATUS_OPTION}
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.STATUS" />}
                />
              </Grid>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    GDS_ADMIN_LIST_DATA_NAME.TYPE
                  )}
                  value={convertGdsType(data[GDS_ADMIN_LIST_DATA_NAME.TYPE])}
                  option={GDS_TYPE_OPTION}
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.TYPE" />}
                />
              </Grid>

              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeSortType}
                  option={GDS_SORT_OPTION}
                  value={
                    data[GDS_ADMIN_LIST_DATA_NAME.SORT_ID]
                      ? GDS_SORT_OPTION[1]
                      : GDS_SORT_OPTION[0]
                  }
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.SORT" />}
                />
              </Grid>

              {/* <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    GDS_ADMIN_LIST_DATA_NAME.HAS_RU
                  )}
                  value={data[GDS_ADMIN_LIST_DATA_NAME.HAS_RU]}
                  option={GDS_HAS_RU_OPTION}
                  label={<TextComponent tid="GDS.ADMIN.PAGINATION.HAS_RU" />}
                />
              </Grid> */}

              <Grid item>
                <FormControlLabel
                  control={<Switch onChange={handleChangeSortHasRu} />}
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.HAS_RU" />}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Pagination
              count={paginationCount}
              page={paginationPage}
              onChange={handleChangePagination}
              size="large"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
