import { TIP_ADMIN_LIST_DATA_NAME } from "./tip-admin-list.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";
import {
  convertBoostRank,
  convertBoostType,
  convertBoostAdditionalList,
} from "../../lib/common/boost/boost.convert";
import { convertPromocode } from "../../lib/common/promocode/promocode.convert";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { convertDatetime } from "../../lib/common/convert/convert.core";
import {
  convertPaymentSystemType,
  convertPaymentValuteType,
} from "../../lib/common/payment/payment.convert";
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from "../../lib/common/coaching/coaching.convert";

export const convertTipAdminList = (d) => ({
  [TIP_ADMIN_LIST_DATA_NAME.TAKE]: d[TIP_ADMIN_LIST_DATA_NAME.TAKE],
  [TIP_ADMIN_LIST_DATA_NAME.LANG]: d[TIP_ADMIN_LIST_DATA_NAME.LANG],

  [TIP_ADMIN_LIST_DATA_NAME.AMOUNT]: d[TIP_ADMIN_LIST_DATA_NAME.AMOUNT],
  [TIP_ADMIN_LIST_DATA_NAME.SKIP]: d[TIP_ADMIN_LIST_DATA_NAME.SKIP],

  [TIP_ADMIN_LIST_DATA_NAME.LIST]: d[TIP_ADMIN_LIST_DATA_NAME.LIST].map(
    (data) => {
      const purchase = data.purchase;

      const purchaseType = convertPurchaseType(
        purchase[TIP_ADMIN_LIST_DATA_NAME.TYPE]
      );

      const gdsItemData = purchase[TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM];

      const gdsItem =
        purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsItemData
          ? {
              [TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM_TITLE]:
                gdsItemData[TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM_TITLE],
              [TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM_ID]:
                gdsItemData[TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM_ID],
            }
          : null;

      const gdsData =
        purchaseType.id === PURCHASE_TYPE_TYPE.GDS
          ? data[TIP_ADMIN_LIST_DATA_NAME.GDS]
          : null;

      const gds =
        purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsData
          ? {
              [TIP_ADMIN_LIST_DATA_NAME.GDS_ID]:
                gdsData[TIP_ADMIN_LIST_DATA_NAME.GDS_ID],
              [TIP_ADMIN_LIST_DATA_NAME.GDS_TITLE]:
                gdsData[TIP_ADMIN_LIST_DATA_NAME.GDS_TITLE],
            }
          : null;

      const boostData = purchase[TIP_ADMIN_LIST_DATA_NAME.BOOST];

      const boost =
        purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
          ? {
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_CURRENT]
              ),
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_DESIRED]
              ),
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_ACTUAL]: convertBoostRank(
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_ACTUAL]
              ),
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_ADDITIONALS]:
                convertBoostAdditionalList(
                  boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_ADDITIONALS]
                ),
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_ID]:
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_ID],
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_TYPE]: convertBoostType(
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_TYPE]
              ),
              [TIP_ADMIN_LIST_DATA_NAME.BOOST_DATA]:
                boostData[TIP_ADMIN_LIST_DATA_NAME.BOOST_DATA],
            }
          : null;

      const coachingData = purchase[TIP_ADMIN_LIST_DATA_NAME.COACHING];

      const coaching =
        purchaseType?.id === PURCHASE_TYPE_TYPE.COACHING
          ? {
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_ADDITIONALS]:
                convertCoachingAdditionalList(
                  coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_ADDITIONALS]
                ),
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_ID]:
                coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_ID],
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_DATA]:
                coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_DATA],
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_TYPE]: convertCoachingType(
                coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_TYPE]
              ),
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_SESSION_AMOUNT]:
                coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_SESSION_AMOUNT],
              [TIP_ADMIN_LIST_DATA_NAME.COACHING_ACTUAL_SESSION]:
                coachingData[TIP_ADMIN_LIST_DATA_NAME.COACHING_ACTUAL_SESSION],
            }
          : null;

      const isPromocode = !!data[TIP_ADMIN_LIST_DATA_NAME.PROMOCODE];

      let promocode = null;

      if (isPromocode) {
        promocode = convertPromocode(
          data[TIP_ADMIN_LIST_DATA_NAME.PROMOCODE],
          data[TIP_ADMIN_LIST_DATA_NAME.PRICE]
        );
      }

      const paid = !!data[TIP_ADMIN_LIST_DATA_NAME.PAYMENT];

      const paymentData = data[TIP_ADMIN_LIST_DATA_NAME.PAYMENT];


      const payment = paid
        ? {
            [TIP_ADMIN_LIST_DATA_NAME.PAYMENT_ID]:
              paymentData[TIP_ADMIN_LIST_DATA_NAME.PAYMENT_ID],
            [TIP_ADMIN_LIST_DATA_NAME.STRIPE_PAID]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.STRIPE_PAID]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.PAYOP_PAID]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.PAYOP_PAID]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.STRIPE_CHECKED]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.STRIPE_CHECKED]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.ELOBOSS_GG]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.ELOBOSS_GG]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.COINBASE_PAID]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.COINBASE_PAID]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.UNITPAY_PAID]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.UNITPAY_PAID]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.MANUAL_PAID]: Boolean(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.MANUAL_PAID]
            ),
            [TIP_ADMIN_LIST_DATA_NAME.CONFIRM]:
              paymentData[TIP_ADMIN_LIST_DATA_NAME.CONFIRM],
            [TIP_ADMIN_LIST_DATA_NAME.SYSTEM]: convertPaymentSystemType(
              paymentData[TIP_ADMIN_LIST_DATA_NAME.SYSTEM]
            ),
          }
        : null;


      const isCustomer = !!purchase[TIP_ADMIN_LIST_DATA_NAME.CUSTOMER];
      const customerData = purchase[TIP_ADMIN_LIST_DATA_NAME.CUSTOMER];

      const customer = isCustomer
        ? {
            [TIP_ADMIN_LIST_DATA_NAME.CUSTOMER_ID]:
              customerData[TIP_ADMIN_LIST_DATA_NAME.CUSTOMER_ID],
            [TIP_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL]:
              customerData[TIP_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL],
          }
        : null;

      const isBooster = !!purchase[TIP_ADMIN_LIST_DATA_NAME.BOOSTER];
      const boosterData = purchase[TIP_ADMIN_LIST_DATA_NAME.BOOSTER];

      const booster = isBooster
        ? {
            [TIP_ADMIN_LIST_DATA_NAME.BOOSTER_ID]:
              boosterData[TIP_ADMIN_LIST_DATA_NAME.BOOSTER_ID],
            [TIP_ADMIN_LIST_DATA_NAME.BOOSTER_EMAIL]:
              boosterData[TIP_ADMIN_LIST_DATA_NAME.BOOSTER_EMAIL],
          }
        : null;

      return {
        [TIP_ADMIN_LIST_DATA_NAME.ID]: data[TIP_ADMIN_LIST_DATA_NAME.ID],
        [TIP_ADMIN_LIST_DATA_NAME.AMOUNT]:
          data[TIP_ADMIN_LIST_DATA_NAME.AMOUNT],
        [TIP_ADMIN_LIST_DATA_NAME.VALUTE]: convertPaymentValuteType(
          data[TIP_ADMIN_LIST_DATA_NAME.VALUTE]
        ),

        [TIP_ADMIN_LIST_DATA_NAME.STATUS]: convertPurchaseStatus(
          data[TIP_ADMIN_LIST_DATA_NAME.STATUS]
        ),

        [TIP_ADMIN_LIST_DATA_NAME.TYPE]: purchaseType,

        [TIP_ADMIN_LIST_DATA_NAME.CREATE_DATE]: convertDatetime(
          data[TIP_ADMIN_LIST_DATA_NAME.CREATE_DATE]
        ),

        [TIP_ADMIN_LIST_DATA_NAME.PAID]: paid,
        [TIP_ADMIN_LIST_DATA_NAME.PAYMENT]: payment,

        [TIP_ADMIN_LIST_DATA_NAME.GDS_ITEM]: gdsItem,
        [TIP_ADMIN_LIST_DATA_NAME.GDS]: gds,
        [TIP_ADMIN_LIST_DATA_NAME.BOOST]: boost,

        [TIP_ADMIN_LIST_DATA_NAME.IS_PROMOCODE]: isPromocode,
        [TIP_ADMIN_LIST_DATA_NAME.PROMOCODE]: promocode,

        [TIP_ADMIN_LIST_DATA_NAME.IS_CUSTOMER]: isCustomer,
        [TIP_ADMIN_LIST_DATA_NAME.CUSTOMER]: customer,

        [TIP_ADMIN_LIST_DATA_NAME.IS_BOOSTER]: isBooster,
        [TIP_ADMIN_LIST_DATA_NAME.BOOSTER]: booster,

        [TIP_ADMIN_LIST_DATA_NAME.COACHING]: coaching,
        [TIP_ADMIN_LIST_DATA_NAME.PURCHASE]: purchase,

        [TIP_ADMIN_LIST_DATA_NAME.METADATA]:
          data[TIP_ADMIN_LIST_DATA_NAME.METADATA],
        [TIP_ADMIN_LIST_DATA_NAME.CONFIRM]:
          data[TIP_ADMIN_LIST_DATA_NAME.CONFIRM],
      };
    }
  ),
});
