import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { SkeletonListComponent } from "../../lib/common/skeleton-list/skeleton-list.component";
import { TextComponent } from "../../lib/common/text";

import { PurchaseBoosterListViewComponent } from "./frame/purchase-booster-list-view.component";

export function PurchaseBoosterListComponent(props) {
  const { isPending, isError, isSuccess, data, errorMessage, isUpdating } =
    props;

  return (
    <Box>
      <Paper sx={{ py: 6, pb: 4 }}>
        <Box>
          <Box sx={{ px: 6 }}>
            <Box sx={{ pb: 4 }}>
              <TextComponent
                variant="title"
                component="div"
                tid="PURCHASE.BOOSTER.LIST.TITLE"
              />
            </Box>
            <Divider />
          </Box>
          {isSuccess && <PurchaseBoosterListViewComponent data={data} />}
          {isPending && (
            <Box sx={{ px: 6, pt: 4, pb: 2 }}>
              <SkeletonListComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ px: 6, pt: 4, pb: 2 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {/* {isPending && <LoaderBarComponent />} */}
          {isUpdating && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
