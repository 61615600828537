import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import SyncIcon from "@mui/icons-material/Sync";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { SkeletonListComponent } from "../../lib/common/skeleton-list/skeleton-list.component";
import { TextComponent } from "../../lib/common/text";

import { PurchaseBoosterOrderListViewComponent } from "./frame/purchase-booster-order-list-view.component";

export function PurchaseBoosterOrderListComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    data,
    errorMessage,
    isUpdating,
    updateList,
  } = props;

  return (
    <Box>
      <Paper sx={{ py: 6, pb: 4 }}>
        <Box>
          <Box sx={{ px: 6 }}>
            <Box sx={{ pb: 4 }}>
              <Heading>
                <TextComponent
                  variant="title"
                  component="div"
                  tid="PURCHASE.BOOSTER_ORDER.LIST.TITLE"
                />
                <IconButton onClick={updateList}>
                  <SyncIcon fontSize="medium" />
                </IconButton>
              </Heading>
            </Box>
            <Divider />
          </Box>
          {isSuccess && <PurchaseBoosterOrderListViewComponent data={data} />}
          {isPending && (
            <Box sx={{ px: 6, pt: 4 }}>
              <SkeletonListComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ px: 6, pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {/* {isPending && <LoaderBarComponent />} */}
          {isUpdating && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}

const Heading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
