import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TipItemUpdateAdminComponent } from "./tip-item-update-admin.component";
import {
  resetTipAdminItemUpdateBoosterFormState,
  uploadTipItemUpdateAdmin,
} from "./tip-item-update-admin.action";
import { TIP_ITEM_UPDATE_ADMIN_STORE_NAME } from "./tip-item-update-admin.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function TipItemUpdateAdminContainer({ tipId, ...props }) {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: state[TIP_ITEM_UPDATE_ADMIN_STORE_NAME],
  }));

  const updateTip = () => {
    dispatch(uploadTipItemUpdateAdmin({ tipId }));
  };

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(resetTipAdminItemUpdateBoosterFormState({ tipId }));
  //   }, 2000);
  // }, [isRequestError(state.form[tipId])]);

  return (
    <TipItemUpdateAdminComponent
      {...props}
      updateTip={updateTip}
      isPending={isRequestPending(state.form[tipId])}
      isError={isRequestError(state.form[tipId])}
      isSuccess={isRequestSuccess(state.form[tipId])}
      errorMessage={getRequestErrorMessage(state.form[tipId])}
    />
  );
}
