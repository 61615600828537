import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
} from "../../main/store/store.service";
import { FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE } from "./finder-gds-item-admin-list.constant";

const initialState = {
  request: initRequestState(),
  gdsId: null,
};

export function finderGdsItemAdminListStore(state = initialState, action) {
  switch (action.type) {
    case FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
        gdsId: action.gdsId,
      };

    case FINDER_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.errorMessage),
      };

    default:
      return state;
  }
}
