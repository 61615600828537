import { httpRequest } from "../../main/http";

import {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE,
} from "./purchase-admin-item-boost-account-data.constant";

import { convertPurchaseAdminItemBoostAccountData } from "./purchase-admin-item-boost-account-data.convert";

export function getPurchaseAdminItemBoostAccountData(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API
            .PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API.PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA.ENDPOINT(
          data.purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseAdminItemBoostAccountData(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseAdminItemBoostAccountData() {
  return async (dispatch, getState) => {
    const { purchaseId } =
      getState()[PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME];

    dispatch({
      type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API
            .PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API.PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA.ENDPOINT(
          purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseAdminItemBoostAccountData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
