import { httpRequest } from "../../main/http";
import {
  TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE,
  TIP_ITEM_CREATE_CUSTOMER_API,
} from "./tip-customer-item-create.constant";

export function uploadTipItemCreateCustomer({ purchaseId, type, price }) {
  return async (dispatch) => {
    dispatch({
      type: TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const resPayment = await httpRequest({
        method: TIP_ITEM_CREATE_CUSTOMER_API.GET.TYPE,
        url: TIP_ITEM_CREATE_CUSTOMER_API.GET.ENDPOINT(purchaseId, type, price),
      });

      dispatch({
        type: TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_SUCCESS,
      });

      if (resPayment.status === 200) {
        window.location = resPayment.data.data;
      }
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: TIP_ITEM_CREATE_CUSTOMER_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
