import { httpRequest } from "../../main/http";

import {
  FINDER_CHAT_GDS_ITEM_ADMIN_LIST_API,
  FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME,
  FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE,
} from "./finder-chat-gds-item-admin-list.constant";

import { convertGdsItemAdminList } from "./finder-chat-gds-item-admin-list.convert";

export function getFinderChatGdsItemAdminList(data) {
  return async (dispatch) => {
    dispatch({
      type: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          FINDER_CHAT_GDS_ITEM_ADMIN_LIST_API.FINDER_CHAT_GDS_ITEM_ADMIN_LIST
            .TYPE,
        url: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_API.FINDER_CHAT_GDS_ITEM_ADMIN_LIST.ENDPOINT(),
        params: {
          [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]:
            data[FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP],
          [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID]:
            data[FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID],
          [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
            data[FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER],
          [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SEARCH]:
            data[FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.SEARCH],
        },
      });

      dispatch({
        type: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertGdsItemAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
