import { FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME } from "./finder-gds-item-admin-list.constant";

import { convertGdsItemStatus } from "../../lib/common/gds-item/gds-item.convert";

export const convertGdsItemAdminList = (d) => ({
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.LIST]: d.list.map((data) => {
    return {
      [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER]:
        data[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER],
      [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]: String(
        data[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]
      ),
      [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID]:
        data[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID],
      [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS]:
        data[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS],
      [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]: convertGdsItemStatus(
        data[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]
      ),
    };
  }),
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]:
    d[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS],
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]:
    d[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT],
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]:
    d[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP],
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.TAKE]:
    d[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.TAKE],
  [FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.AMOUNT]:
    d[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.AMOUNT],
});
