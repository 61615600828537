import React, { useCallback, useRef } from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { TextComponent } from "../../lib/common/text";
import Grid from "@mui/material/Grid";

import Pagination from "@mui/material/Pagination";

import { GDS_ITEM_ADMIN_LIST_DATA_NAME } from "../gds-item-admin-list/gds-item-admin-list.constant";
import { FieldTextComponent } from "../../lib/common/field-text/field-text.component";
import { FINDER_GDS_ITEM_ADMIN_LIST_STORE_NAME } from "../finder-gds-item-admin-list/finder-gds-item-admin-list.constant";
import { useDispatch, useSelector } from "react-redux";
import { getRequestData } from "../../main/store/store.service";
import { getFinderGdsItemAdminList } from "../finder-gds-item-admin-list/finder-gds-item-admin-list.action";

export function FinderGdsItemAdminListPaginationComponent(props) {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: getRequestData(state[FINDER_GDS_ITEM_ADMIN_LIST_STORE_NAME].request),
  }));

  const data = state;

  const onChangeFilter = (data) => {
    dispatch(getFinderGdsItemAdminList({ ...state, ...data }));
  };

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]: data?.take * value - data?.take,
    });
  };

  const useDebouncedCallback = (callback, delay) => {
    const timeoutRef = useRef(null);

    return useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(...args);
        }, delay);
      },
      [callback, delay]
    );
  };

  const handleChangeSearch = useDebouncedCallback((name, value) => {
    onChangeFilter({
      [name]: value,
    });
  }, 600);

  const paginationCount = Math.ceil(data?.amount / data?.take);
  const paginationPage = Math.ceil(data?.skip / data?.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid container spacing={4}>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="FINDER.ADMIN.PAGINATION.SEARCH" />}
              placeholder="FINDER.ADMIN.PAGINATION.SEARCH_PLACEHOLDER"
              name="search"
              onChange={(e) =>
                handleChangeSearch(e.target.name, e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Pagination
              count={paginationCount}
              page={paginationPage}
              onChange={handleChangePagination}
              size="large"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
