import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_ADMIN_ITEM_DATA_NAME } from "../../purchase-admin-item/purchase-admin-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../../lib/common/purchase/purchase.type";
import { GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-item-admin-item/gds-item-admin-item.constant";
import { GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../gds-admin-item/gds-admin-item.constant";
import { USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../user-admin-item/user-admin-item.constant";
import { ButtonCopyComponent } from "../../../lib/common/button-copy/button-copy.component";
import { formatDatePurchase } from "../../../lib/common/button-copy/helper";

export function PurchaseAdminItemDataViewComponent(props) {
  const { data } = props;
  const navigate = useNavigate();
  const href = window.location.href;

  const idDateValue = `${data[PURCHASE_ADMIN_ITEM_DATA_NAME.ID]} ${formatDatePurchase(data[PURCHASE_ADMIN_ITEM_DATA_NAME.CREATE_DATE])}`;

  const boostType =
    data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST]?.type?.text ||
    data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING]?.type?.text;

  const coachingValue =
    data.type.id === PURCHASE_TYPE_TYPE.COACHING
      ? `${boostType} ${data?.coaching?.sessionAmount || ""} ${Array.isArray(data?.coaching?.additionals) ? data?.coaching?.additionals?.map((e) => e.text).join(", ") : ""}`
      : null;

  const accountValue =
    data.type.id === PURCHASE_TYPE_TYPE.GDS
      ? `${data?.gdsItem?.titleEn || data?.gdsItem?.titleRu || data?.gds?.titleEn || data?.gds?.titleRu || ""} ${data?.gds?.id ?? ""} `
      : null;

  const boostValue =
    data.type.id === PURCHASE_TYPE_TYPE.BOOST
      ? `${boostType} ${data?.boost?.current?.text || ""} ${data?.boost?.desired?.text || ""} ${Array.isArray(data?.boost?.additionals) ? data?.boost?.additionals?.map((e) => e.text).join(", ") : ""}`
      : null;

  const totalPrice = Number(data[PURCHASE_ADMIN_ITEM_DATA_NAME.PRICE]);

  const totalPriceDiscount = (totalPrice * 0.9)
    .toFixed(2)
    .replace(".", ",");

  const purchaseValue = coachingValue || accountValue || boostValue || "";

  const handleLinkRoute = (path) => (e) => {
    e.preventDefault();
    return navigate(path);
  };

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid sx={{ position: "relative" }} container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.ID"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.ID]}
            </TextComponent>
          </Grid>
          <Grid item sx={{ position: "absolute", top: "30px", left: "190px" }}>
            <ButtonCopyComponent text={idDateValue} link={href} />
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.CREATE_DATE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CREATE_DATE]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.LANG"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.LANG]?.text}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.STATUS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.STATUS]?.text}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.NOTES"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.NOTES] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.NOTES_NULL" />
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <Grid
              alignItems="center"
              justifyContent="start"
              container
              flexDirection="row"
            >
              <Grid sx={{ marginRight: "10px" }} item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CONTACTS"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CONTACTS] || (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.CONTACTS_NULL" />
                  )}
                </TextComponent>
              </Grid>
              <ButtonCopyComponent text={data?.contactValue} />
            </Grid>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.COMMENTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.COMMENTS] || (
                <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.COMMENTS_NULL" />
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.TIMEZONE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TIMEZONE]?.text}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.METADATA"
            />
            <Grid container spacing={1}>
              {data.metadata?.static?.referalId && (
                <Grid item>
                  <TextComponent
                    variant="dataViewContent"
                    tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.REFERAL`}
                    tvalue={{ value: data.metadata?.static?.referalId }}
                    sx={{ lineHeight: "1.3em" }}
                  />
                </Grid>
              )}

              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.view && (
                <>
                  {Object.entries(
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.view
                  ).map((item) => (
                    <Grid item>
                      <TextComponent
                        key={item}
                        variant="dataViewContent"
                        tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.${item[0].toUpperCase()}`}
                        tvalue={{ value: item[1] }}
                        sx={{ lineHeight: "1.3em" }}
                      />
                    </Grid>
                  ))}
                </>
              )}
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]
                ?.purchaseByGiftCard && (
                <>
                  <Grid item>
                    <TextComponent
                      variant="dataViewContent"
                      tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.GIFT_CARD`}
                      tvalue={{ value: data.metadata?.purchaseByGiftCard }}
                      sx={{ lineHeight: "1.3em" }}
                    />
                  </Grid>
                </>
              )}
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.isDirectlyPay && (
                <>
                  <Grid item>
                    <TextComponent
                      variant="dataViewContent"
                      tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.DIRECTLY_PAY`}
                      tvalue={{ value: data.metadata?.isDirectlyPay }}
                      sx={{ lineHeight: "1.3em" }}
                    />
                  </Grid>
                </>
              )}
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA]?.isDirectlyPay && (
                <>
                  <Grid item>
                    <TextComponent
                      variant="dataViewContent"
                      tid={`PURCHASE.ADMIN.ITEM_DATA.METADATA_DATA.DIRECTLY_PAY`}
                      tvalue={{ value: data.metadata?.isDirectlyPay }}
                      sx={{ lineHeight: "1.3em" }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE].id ===
      PURCHASE_TYPE_TYPE.BOOST ? (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_TYPE"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].type?.text}
              </TextComponent>
            </Grid>
            {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].current?.value && (
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_CURRENT"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].current?.text}
                </TextComponent>
              </Grid>
            )}
            {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].desired?.value && (
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_DESIRED"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].desired?.text}
                </TextComponent>
              </Grid>
            )}
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_ADDITIONALS"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST][
                  PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST_ADDITIONALS
                ] &&
                data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST][
                  PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST_ADDITIONALS
                ]?.length ? (
                  data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST][
                    PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST_ADDITIONALS
                  ]
                    .map((item) => item.text)
                    .join(", ")
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_ADDITIONALS_NULL" />
                )}
              </TextComponent>
            </Grid>
            {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].desired.value && (
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_ACTUAL"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].actual?.text || (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_ACTUAL_NULL" />
                  )}
                </TextComponent>
              </Grid>
            )}

            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_DATA"
              />

              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST]?.data &&
              Object.keys(data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST].data)
                ?.length ? (
                <Grid>
                  {Object.entries(
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST]?.data
                  ).map((item) => (
                    <Grid key={item} item>
                      {Array.isArray(item[1]) ? (
                        <>
                          <TextComponent
                            variant="dataViewContent"
                            tid={`PURCHASE.ADMIN.ITEM_DATA.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                            sx={{ lineHeight: "1.3em" }}
                          />
                          <ListElem>
                            {item[1].map((elem) => (
                              <TextComponent
                                variant="dataViewContent"
                                tid={elem}
                                sx={{ lineHeight: "1.3em" }}
                              />
                            ))}
                          </ListElem>
                        </>
                      ) : (
                        <TextComponent
                          variant="dataViewContent"
                          tid={`PURCHASE.ADMIN.ITEM_DATA.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                          tvalue={{ value: item[1] }}
                          sx={{ lineHeight: "1.3em" }}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_DATA_NULL"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_TYPE"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.BOOST_NOT_SET"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Divider />
      </Grid>

      <Grid item sx={{ position: "relative", padding: "unset !important" }}>
        <Grid item sx={{ position: "absolute", left: "180px", top: "20px" }}>
          <ButtonCopyComponent text={purchaseValue} />
        </Grid>
      </Grid>

      {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE].id === PURCHASE_TYPE_TYPE.GDS &&
      data.gdsItem ? (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ITEM_ID"
              />
              <Link
                variant="body1"
                underline="always"
                sx={{ color: "#fff" }}
                target="_blank"
                href={GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                  gdsItemId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS_ITEM].id,
                  gdsId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id,
                })}
                onClick={handleLinkRoute(
                  GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                    gdsItemId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS_ITEM].id,
                    gdsId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id,
                  })
                )}
              >
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS_ITEM].id}
                </TextComponent>
              </Link>
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ITEM_TITLE"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS_ITEM].titleEn}
              </TextComponent>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ITEM_ID"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ITEM_NOT_SET"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Divider />
      </Grid>

      {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE].id === PURCHASE_TYPE_TYPE.GDS &&
      data.gds ? (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ID"
              />
              <Link
                variant="body1"
                underline="always"
                sx={{ color: "#fff" }}
                target="_blank"
                href={GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(
                  data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id
                )}
                onClick={handleLinkRoute(
                  GDS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id
                  )
                )}
              >
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].id}
                </TextComponent>
              </Link>
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_TITLE"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.GDS].titleEn}
              </TextComponent>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_ID"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.GDS_NOT_SET"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA].view.platform ? (
        <>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PLATFORM_TITLE"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA].view?.platform}
                </TextComponent>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
        </>
      ) : (
        ""
      )}

      {data[PURCHASE_ADMIN_ITEM_DATA_NAME.TYPE].id ===
      PURCHASE_TYPE_TYPE.COACHING ? (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_TYPE"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING].type.text}
              </TextComponent>
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_SESSION_AMOUNT"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_SESSION_AMOUNT_VALUE"
                tvalue={{
                  value:
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING].sessionAmount,
                }}
              />
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_ACTUAL_SESSION"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_ACTUAL_SESSION_VALUE"
                tvalue={{
                  value: String(
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING].actualSession
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_ADDITIONALS"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING][
                  PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING_ADDITIONALS
                ]?.length ? (
                  data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING][
                    PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING_ADDITIONALS
                  ]
                    .map((item) => item.text)
                    .join(", ")
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_ADDITIONALS_NULL" />
                )}
              </TextComponent>
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_DATA"
              />

              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING].data ? (
                <Grid>
                  {Object.entries(
                    data[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING].data
                  ).map((item) => (
                    <Grid item>
                      <TextComponent
                        variant="dataViewContent"
                        tid={`PURCHASE.ADMIN.ITEM_DATA.COACHING_DATA_DATA.${item[0].toUpperCase()}`}
                        tvalue={{ value: item[1] }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_DATA_NULL"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_TYPE"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.ADMIN.ITEM_DATA.COACHING_NOT_SET"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          <Grid item sx={{ position: "relative" }}>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.PRICE"
            />
            <TextComponent variant="dataViewContent">
              {Number(data[PURCHASE_ADMIN_ITEM_DATA_NAME.PRICE]).toFixed(2)}
            </TextComponent>

            <Grid
              item
              sx={{ position: "absolute", left: "120px", top: "20px" }}
            >
              <ButtonCopyComponent text={totalPriceDiscount} />
            </Grid>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.VALUTE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.VALUTE].text}
            </TextComponent>
          </Grid>

          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_PROMOCODE] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PROMOCODE_NAME"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PROMOCODE].name}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PROMOCODE_FACTOR"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PROMOCODE].factor.text}
                </TextComponent>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.IS_PROMOCODE"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_PROMOCODE] ? (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_PROMOCODE_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_PROMOCODE_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.BONUS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA].static?.bonus ||
                "0"}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.REWARD"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA].static?.reward ||
                "0"}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.ADMIN.ITEM_DATA.POPUP"
            />
            {data[PURCHASE_ADMIN_ITEM_DATA_NAME.METADATA].static?.popupBonus ? (
              <TextComponent
                tid="PURCHASE.ADMIN.ITEM_DATA.POPUP_TRUE"
                variant="dataViewContent"
              />
            ) : (
              <TextComponent
                tid="PURCHASE.ADMIN.ITEM_DATA.POPUP_FALSE"
                variant="dataViewContent"
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAID] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_SYSTEM"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT].system.text}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAYMENT].confirm ? (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM_TRUE" />
                  ) : (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAYMENT_CONFIRM_FALSE" />
                  )}
                </TextComponent>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.PAID"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.PAID] ? (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAID_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.PAID_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_CUSTOMER] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_ID"
                />
                <Link
                  variant="body1"
                  underline="always"
                  sx={{ color: "#fff" }}
                  target="_blank"
                  href={USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                    userId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].id,
                  })}
                  onClick={handleLinkRoute(
                    USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                      userId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].id,
                    })
                  )}
                >
                  <TextComponent variant="dataViewContent">
                    {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].id}
                  </TextComponent>
                </Link>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_LOGIN"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].login}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_EMAIL"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].email}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_TIMEZONE"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].timezone.text}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_EMAIL_CONFIRM"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].confirmEmail ? (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_EMAIL_CONFIRM_TRUE" />
                  ) : (
                    <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.CUSTOMER_EMAIL_CONFIRM_FALSE" />
                  )}
                </TextComponent>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.IS_CUSTOMER"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_CUSTOMER] ? (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_CUSTOMER_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_CUSTOMER_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_BOOSTER] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOSTER_ID"
                />
                <Link
                  variant="body1"
                  underline="always"
                  sx={{ color: "#fff" }}
                  target="_blank"
                  href={USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                    userId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER].id,
                  })}
                  onClick={handleLinkRoute(
                    USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
                      userId: data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER].id,
                    })
                  )}
                >
                  <TextComponent variant="dataViewContent">
                    {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER].id}
                  </TextComponent>
                </Link>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOSTER_LOGIN"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER]?.login}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOSTER_EMAIL"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER]?.email}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.ADMIN.ITEM_DATA.BOOSTER_TIMEZONE"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER].timezone.text}
                </TextComponent>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.ADMIN.ITEM_DATA.IS_BOOSTER"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_BOOSTER] ? (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_BOOSTER_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.ADMIN.ITEM_DATA.IS_BOOSTER_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const ListElem = styled.div`
  margin-left: 12px;
`;
