import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import stripeLogo from "../../../css/img/logo-stripe.svg";
import nowpaymentsLogo from "../../../css/img/nowpayments.svg";
import methodsStripe from "../../../css/img/methods-stripe.svg";
import methodsCryptoIcon from "../../../css/img/methods-coinbase.svg";

export const ButtonPayment = ({ type, onClick, ...props }) => {
  switch (type) {
    case "stripe":
      return (
        <ButtonStripe
          {...props}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          <PaymentList>
            <PaymentText>
              <OpPaymentIcon src={stripeLogo} />
            </PaymentText>
            <PaymentMethod>
              <img alt="payment" width={300} src={methodsStripe} />
            </PaymentMethod>
          </PaymentList>
        </ButtonStripe>
      );

    case "nowpayments":
      return (
        <ButtonCrypto
          {...props}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          <PaymentList>
            <PaymentText>
              <NowpaymentsPaymentIcon src={nowpaymentsLogo} />
            </PaymentText>
            <PaymentMethod>
              <img
                src={methodsCryptoIcon}
                alt="methods nowpayments"
                width={269}
                height={24}
              />
            </PaymentMethod>
          </PaymentList>
        </ButtonCrypto>
      );
    default:
      return <ButtonElement {...props} />;
  }
};

const ButtonElement = styled(Button)`
  && {
    padding: 12px 24px !important;
    border-radius: 8px !important;
    box-shadow: unset !important;
    height: unset;
  }
`;

const ButtonCrypto = styled(ButtonElement)`
  && {
    padding: 20px 24px !important;
  }
`;

const ButtonStripe = styled(ButtonElement)`
  @media screen and (max-width: 720px) {
  }
`;

const PaymentList = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media all and (max-width: 720px) {
    justify-content: center;
  }
`;

const PaymentText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpPaymentIcon = styled.img`
  height: 32px;
  width: 80px;
  vertical-align: center;
`;

const NowpaymentsPaymentIcon = styled.img`
  height: 21px;
  width: 163px;
  vertical-align: center;
`;

const PaymentMethod = styled.div`
  img {
    transform: translateZ(0);
    transform: translate3d(0, 0, 0);
  }
  vertical-align: center;

  @media all and (max-width: 720px) {
    display: none;
  }
`;
