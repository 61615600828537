export const GDS_ADMIN_ITEM_DATA_STORE_NAME = "GDS_ADMIN_ITEM_DATA";

export const GDS_ADMIN_ITEM_DATA_API = {
  GDS_ADMIN_ITEM_DATA: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/admin`,
    TYPE: "GET",
  },
};

export const GDS_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: "GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "GDS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const GDS_ADMIN_ITEM_DATA_DATA_NAME = {
  ORDER: "order",
  PRICE_RU: "priceRu",
  STOCK_RU: "stockRu",
  PRICE_EN: "priceEn",
  STOCK_EN: "stockEn",
  TITLE_EN: "titleEn",
  TITLE_RU: "titleRu",
  DESCRIPTION_EN: "descriptionEn",
  DESCRIPTION_RU: "descriptionRu",
  BODY_EN: "bodyEn",
  BODY_RU: "bodyRu",
  URL_EN: "urlEn",
  URL_RU: "urlRu",
  FILE_PREVIEW_RU: "filePreviewImageRu",
  FILE_PREVIEW_EN: "filePreviewImageEn",
  FILE_LIST_EN: "fileImageListEn",
  FILE_LIST_RU: "fileImageListRu",
  CATEGORY: "category",
  TYPE: "type",
  TAG: "tag",
  ID: "id",

  STATUS: "status",
  CREATE_DATE: "createDate",
  UPDATE_DATE: "updateDate",
  HAS_VERIFY: "hasVerify",
  HAS_RU: "hasRu",
};
