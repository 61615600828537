import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { NavigationOnlineComponent } from "./navigation-online-item.component";
import {
  getBoosterStatus,
  uploadBoosterStatusForm,
} from "./navigation-online-item.action";

export function NavigationOnlineItemContainer(props) {
  const [isOnline, setIsOnline] = useState(false);
  const dispatch = useDispatch();
  const intervalRef = useRef(null);

  const ONLINE_THRESHOLD = 20 * 60 * 1000;
  const UPDATE_INTERVAL = 15 * 60 * 1000;

  const checkOnlineStatus = (date) => {
    if (!date) return false;
    return new Date() - new Date(date) <= ONLINE_THRESHOLD;
  };

  const fetchStatus = async () => {
    try {
      const result = await getBoosterStatus();
      const lastOnline = result?.data?.online;
      setIsOnline(checkOnlineStatus(lastOnline));
    } catch (error) {}
  };

  const updateOnlineStatus = () => {
    dispatch(uploadBoosterStatusForm({ online: new Date().toISOString() }));
  };

  const startInterval = () => {
    if (!intervalRef.current) {
      updateOnlineStatus();
      intervalRef.current = setInterval(updateOnlineStatus, UPDATE_INTERVAL);
    }
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    fetchStatus();

    const handleFocus = () => {
      fetchStatus();
      startInterval();
    };
    const handleBlur = stopInterval;

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      stopInterval();
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return <NavigationOnlineComponent isOnline={isOnline} {...props} />;
}
