import { TIP_ADMIN_LIST_ACTION_TYPE } from "./tip-admin-list.constant";
import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  setRequestUpdatePending,
  setRequestUpdateSuccess,
} from "../../main/store/store.service";

const initialState = {
  request: initRequestState(),
};

export function tipAdminListStore(state = initialState, action) {
  switch (action.type) {
    case TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
      };

    case TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING:
      return {
        ...state,
        request: setRequestUpdatePending(state.request),
      };

    case TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        request: setRequestUpdateSuccess(state.request, action.data),
      };

    case TIP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.errorMessage),
      };

    default:
      return state;
  }
}
