import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { TabContainer } from "../../lib/common/tab/tab.container";
import { FinderChatGdsItemAdminListContainer } from "./finder-chat-gds-item-admin-list.container";
import { FINDER_TAB_LIST } from "./finder-chat-gds-item-admin-list.constant";
import { FinderChatGdsItemAdminListPaginationContainer } from "../finder-chat-gds-item-admin-list-pagination/finder-gds-item-admin-list-pagination.container";

export function FinderChatGdsItemAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <TabContainer tab={FINDER_TAB_LIST} />
        </Grid>

        <Grid item>
          <FinderChatGdsItemAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <FinderChatGdsItemAdminListContainer />
        </Grid>
        <Grid item>
          <FinderChatGdsItemAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
