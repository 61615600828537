import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import styled from "styled-components";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PurchaseBoosterItemUpdateBoosterContainer } from "../../purchase-booster-item-update-booster/purchase-booster-item-update-booster.container";
import { ALLOWED_BOOST_TYPES } from "../purchase-booster-order-list.constant";

export function PurchaseBoosterOrderListViewComponent(props) {
  const { data } = props;

  if (data?.list?.length === 0 || !data.list) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.BOOSTER_ORDER.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const getCurent = (item) => {
    switch (item.boost.type.value) {
      case "APEX_BOOST_TYPE_BADGE":
        return (
          <>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                variant="body1"
              >
                {item?.boost?.data?.badge.map((badge) => (
                  <p>{badge}</p>
                ))}
              </TextComponent>
            </Grid>
          </>
        );
      default:
        return (
          <Grid item>
            <TextComponent
              sx={{
                wordBreak: "break-all",
                whiteSpace: "break-spaces",
              }}
              tid="PURCHASE.ADMIN.LIST.BOOST"
              variant="body1"
              tvalue={{
                current: item?.boost?.current?.text,
                desired: item?.boost?.desired?.text,
              }}
            />
          </Grid>
        );
    }
  };
  return (
    <List sx={{ pt: 4 }}>
      {data?.list.map((item, itemIndex) => (
        <React.Fragment key={itemIndex}>
          <Row>
            <InfoContainer>
              <Grid item>
                <TextComponent
                  tid="PURCHASE.ADMIN.LIST.ID"
                  variant="body1"
                  component="div"
                  tvalue={{ id: item.id }}
                />
                <TextComponent
                  tid="PURCHASE.ADMIN.LIST.CREATE_DATE"
                  variant="body1"
                  component="div"
                  tvalue={{ date: item.createDate }}
                />
              </Grid>
              <Grid item>
                {item.boost && (
                  <React.Fragment>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.TYPE"
                      variant="body1"
                      tvalue={{ type: item.boost.type.text }}
                    />
                    {getCurent(item)}
                    {item.boost.additionals && (
                      <Grid item>
                        <TextComponent variant="body1">
                          {item.boost.additionals.map((i) => i.text).join(", ")}
                        </TextComponent>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {item.gds && (
                  <React.Fragment>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.GDS"
                      variant="body1"
                      tvalue={{
                        title: item.gds.titleEn,
                        id: item.gds.id,
                      }}
                    />
                    {item.gdsItem && (
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.GDS_ITEM"
                        variant="body1"
                        tvalue={{
                          title: item.gdsItem.titleEn,
                          id: item.gdsItem.id,
                        }}
                      />
                    )}
                  </React.Fragment>
                )}
                {item.type.value === "GDS" && (
                  <TextComponent
                    tid="PURCHASE.ADMIN.LIST.NAME_ITEM"
                    variant="body1"
                    tvalue={{
                      name: item.metadata.static.title,
                    }}
                  />
                )}
                {item.coaching && (
                  <React.Fragment>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.TYPE"
                      variant="body1"
                      tvalue={{ type: item.coaching.type.text }}
                    />
                    <TextComponent
                      sx={{
                        wordBreak: "break-all",
                        whiteSpace: "break-spaces",
                      }}
                      tid="PURCHASE.ADMIN.LIST.COACHING"
                      variant="body1"
                      tvalue={{
                        sessionAmount: item.coaching.sessionAmount,
                        actualSession: item.coaching.actualSession,
                      }}
                    />
                    {item.coaching.additionals && (
                      <TextComponent variant="body1">
                        {item.coaching.additionals
                          .map((i) => i.text)
                          .join(", ")}
                      </TextComponent>
                    )}
                  </React.Fragment>
                )}
              </Grid>

              {ALLOWED_BOOST_TYPES.includes(item?.boost?.type.id) && (
                <div>
                  <InlineText>
                    <TextComponent
                      tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA_DATA.REGION"
                      variant="body1"
                      component="div"
                    />
                    <TextComponent variant="body1" component="div">
                      {item.boost.data.region}
                    </TextComponent>
                  </InlineText>
                  <InlineText>
                    <TextComponent
                      tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA_DATA.PLATFORMTYPE"
                      variant="body1"
                      component="div"
                    />
                    <TextComponent variant="body1" component="div">
                      {item.boost.data.platformType}
                    </TextComponent>
                  </InlineText>
                </div>
              )}

              {item.notes && (
                <Grid item>
                  <TextComponent
                    tid={item.notes}
                    variant="body1"
                    component="div"
                  />
                </Grid>
              )}
            </InfoContainer>
            <PurchaseBoosterItemUpdateBoosterContainer purchaseId={item.id} />
          </Row>
          {itemIndex !== data.list.length - 1 && (
            <ListItem>
              <Divider sx={{ width: "100%", opacity: 0.35 }} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}

const Row = styled.div`
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: grid;
  gap: 40px;
  flex-wrap: wrap;
  grid-template-columns: 1fr 2fr 1.5fr 0.5fr;
  @media all and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const InlineText = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
