import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";

import { PurchaseAdminItemChatCreateFormComponent } from "./frame/purchase-admin-item-chat-create-form.component";

export function PurchaseAdminItemChatCreateComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Formik
            initialValues={initialValue}
            validate={validation}
            onSubmit={(values, { resetForm }) => {
              onSubmitForm(values);
              resetForm();
            }}
          >
            {(props) => (
              <PurchaseAdminItemChatCreateFormComponent
                {...props}
                isPending={isPending}
                isError={isError}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            )}
          </Formik>
          {isError && (
            <Box sx={{ mt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {/* {isPending && <LoaderBarComponent />} */}
        </Box>
      </Paper>
    </Box>
  );
}
