import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useState } from "react";

import { TextComponent } from "../text/text.component";

export const FieldSelectComponent = ({
  errorText,
  error,
  label,
  option,
  name,
  setFieldValue,
  onChange,
  value = null,
  onBlur,
  multiple,
  size,
  defaultValue,
  ...props
}) => {
  if (defaultValue) {
    value = defaultValue;
  }

  const [valueData, setValue] = useState(() => {
    try {
      if (value) {
        return multiple
          ? value.map((v) =>
              option.find((i) => Number(i.value) === Number(v.id))
            )
          : option.find((i) => Number(i.value) === Number(value.id));
      } else {
        return multiple ? [] : null;
      }
    } catch (error) {
      console.error("Error processing valueData:", error);
      return multiple ? [] : null; // Return default value in case of error
    }
  });

  const handleChange = (e) => {
    // setFieldValue(name, value, true);

    e.target.name = name;

    onChange(e);

    setValue(e.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="fieldLabel">{label}</Typography>
      </Grid>
      <Grid item>
        <Select
          defaultValue={defaultValue}
          size={size}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              return selected.map((value, i) => (
                <span>
                  {i !== 0 && ", "}
                  <TextComponent
                    component="span"
                    tid={value.tid || value.text}
                    variant="selectOption"
                  />
                </span>
              ));
            } else {
              return (
                <TextComponent
                  component="span"
                  tid={selected.tid || selected.text}
                  variant="selectOption"
                />
              );
            }
          }}
          onBlur={onBlur}
          id={name}
          multiple={multiple}
          value={valueData}
          onChange={handleChange}
        >
          {option.map((opt) => (
            <MenuItem key={opt.id} value={opt}>
              <TextComponent tid={opt.tid} variant="selectOption" />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {error && (
        <Grid item>
          <Typography variant="errorText">{errorText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
