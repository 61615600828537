export const FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH = "/admin/finder/gds-item";

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH =
  "/admin/finder/chat/gds-item";

export const FINDER_TAB_LIST = [
  {
    id: 0,
    tid: "NAVIGATION.TAB.FINDER.GDS_ITEM_LIST",
    path: FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.FINDER.MESSAGE_LIST",
    path: FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
  },
];

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC = ({
  purchaseId,
}) => `/admin/purchase/${purchaseId}/chat`;

// /admin/purchase/1126/chat

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_STORE_NAME =
  "FINDER_FINDER_CHAT_GDS_ITEM_ADMIN_LIST";

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_API = {
  FINDER_CHAT_GDS_ITEM_ADMIN_LIST: {
    ENDPOINT: () => `/chat-message/finder/chat/admin/list`,
    TYPE: "GET",
  },
};

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING:
    "FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS:
    "FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "FINDER_CHAT_GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME = {
  ID: "id",
  TYPE: "type",
  USER: "user",
  VIEW: "view",
  DATA: "data",
  CREATE_DATE: "createDate",
  PURCHASE: "purchase",
  ID: "id",
  GDS_ITEM: "gdsItem",
  GDS: "gds",
  BOOST: "boost",
  BOOST_ACCOUNT: "boostAccount",

  LIST: "list",
  SKIP: "skip",
  TAKE: "take",
  BOUGHT: "bought",
  SORT_ORDER: "sortOrder",
  SORT_ID: "sortId",
  AMOUNT: "amount",
  SEARCH: "search",
};
