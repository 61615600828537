import { TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE } from "./tip-item-update-admin.constant";
import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  resetRequestStatus,
  resetRequestErrorStatus,
} from "../../main/store/store.service";

const initialState = {
  form: {},
};

export function tipItemUpdateAdminStore(state = initialState, action) {
  const { tipId } = action.payload || {};

  if (!tipId) return state;

  switch (action.type) {
    case TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_RESET:
      return {
        ...state,
        form: {
          ...state.form,
          [tipId]: state.form[tipId]
            ? resetRequestErrorStatus(state.form[tipId])
            : resetRequestErrorStatus(initRequestState()),
        },
      };

    case TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_PENDING:
      return {
        ...state,
        form: {
          ...state.form,
          [tipId]: state.form[tipId]
            ? setRequestPending(state.form[tipId])
            : setRequestPending(initRequestState()),
        },
      };

    case TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          [tipId]: state.form[tipId]
            ? setRequestSuccess(state.form[tipId])
            : setRequestSuccess(initRequestState()),
        },
      };

    case TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          [tipId]: state.form[tipId]
            ? setRequestError(state.form[tipId], action.payload.errorMessage)
            : setRequestError(initRequestState(), action.payload.errorMessage),
        },
      };

    default:
      return state;
  }
}
