import { convertSelectFieldValue } from "../../lib/common/field-select/field-select.convert";
import { USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from "./user-admin-item-update-data.constant";

export const convertUserAdminItemUpdateDataFormData = (data) => ({
  [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD]:
    data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD],
  [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL]:
    data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL],
  [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE]: convertSelectFieldValue(
    data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE]
  ),
  [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE]: Number(
    convertSelectFieldValue(
      data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE]
    )
  ),
  [USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE]: convertSelectFieldValue(
    data[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE]
  ),
});
