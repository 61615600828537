import { PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME } from "./purchase-booster-item-coaching-demo-list.constant";

import { convertFile } from "../../lib/common/file/file.convert";
import { convertDatetime } from "../../lib/common/convert/convert.core";

export const convertPurchaseBoosterItemCoachingDemoList = (d) => {
  return {
    list: d.list.map((data) => {
      return {
        [PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.ID]:
          data[PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.ID],
        [PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.CREATE_DATE]:
          convertDatetime(
            data[PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.CREATE_DATE]
          ),
        [PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.INFO]:
          data[PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.INFO],
        [PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.DEMO_FILE]:
          convertFile(
            data[PURCHASE_BOOSTER_ITEM_COACHING_DEMO_LIST_DATA_NAME.DEMO_FILE]
          ),
      };
    }),
  };
};
