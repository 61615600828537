import { GDS_ADMIN_LIST_DATA_NAME } from "./gds-admin-list.constant";

import {
  convertGdsStatus,
  convertGdsType,
  convertGdsCategory,
  convertGdsCategoryList,
} from "../../lib/common/gds/gds.convert";
import { convertDatetime } from "../../lib/common/convert/convert.core";

export const convertGdsAdminList = (d) => ({
  [GDS_ADMIN_LIST_DATA_NAME.LIST]: d.list.map((data) => {
    return {
      [GDS_ADMIN_LIST_DATA_NAME.ORDER]: data[GDS_ADMIN_LIST_DATA_NAME.ORDER],
      [GDS_ADMIN_LIST_DATA_NAME.TITLE_EN]:
        data[GDS_ADMIN_LIST_DATA_NAME.TITLE_EN],
      [GDS_ADMIN_LIST_DATA_NAME.TITLE_RU]:
        data[GDS_ADMIN_LIST_DATA_NAME.TITLE_RU],
      [GDS_ADMIN_LIST_DATA_NAME.PRICE_EN]:
        data[GDS_ADMIN_LIST_DATA_NAME.PRICE_EN],
      [GDS_ADMIN_LIST_DATA_NAME.PRICE_RU]:
        data[GDS_ADMIN_LIST_DATA_NAME.PRICE_RU],
      [GDS_ADMIN_LIST_DATA_NAME.STOCK_EN]:
        data[GDS_ADMIN_LIST_DATA_NAME.STOCK_EN],
      [GDS_ADMIN_LIST_DATA_NAME.STOCK_RU]:
        data[GDS_ADMIN_LIST_DATA_NAME.STOCK_RU],
      [GDS_ADMIN_LIST_DATA_NAME.URL_RU]: data[GDS_ADMIN_LIST_DATA_NAME.URL_RU],
      [GDS_ADMIN_LIST_DATA_NAME.URL_EN]: data[GDS_ADMIN_LIST_DATA_NAME.URL_EN],
      [GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA]: convertDatetime(
        data[GDS_ADMIN_LIST_DATA_NAME.CREATE_DATA]
      ),
      [GDS_ADMIN_LIST_DATA_NAME.ID]: data[GDS_ADMIN_LIST_DATA_NAME.ID],

      [GDS_ADMIN_LIST_DATA_NAME.STATUS]: convertGdsStatus(
        data[GDS_ADMIN_LIST_DATA_NAME.STATUS]
      ),
      [GDS_ADMIN_LIST_DATA_NAME.TYPE]: convertGdsType(
        data[GDS_ADMIN_LIST_DATA_NAME.TYPE]
      ),
      [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: convertGdsCategoryList(
        data[GDS_ADMIN_LIST_DATA_NAME.CATEGORY]
      ),

      [GDS_ADMIN_LIST_DATA_NAME.HAS_VERIFY]:
        data[GDS_ADMIN_LIST_DATA_NAME.HAS_VERIFY],

      [GDS_ADMIN_LIST_DATA_NAME.HAS_RU]: data[GDS_ADMIN_LIST_DATA_NAME.HAS_RU],
    };
  }),
  [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: d[GDS_ADMIN_LIST_DATA_NAME.CATEGORY],
  [GDS_ADMIN_LIST_DATA_NAME.AMOUNT]: d[GDS_ADMIN_LIST_DATA_NAME.AMOUNT],
  [GDS_ADMIN_LIST_DATA_NAME.SKIP]: d[GDS_ADMIN_LIST_DATA_NAME.SKIP],
  [GDS_ADMIN_LIST_DATA_NAME.TAKE]: d[GDS_ADMIN_LIST_DATA_NAME.TAKE],
  [GDS_ADMIN_LIST_DATA_NAME.STATUS]: d[GDS_ADMIN_LIST_DATA_NAME.STATUS],
  [GDS_ADMIN_LIST_DATA_NAME.TYPE]: d[GDS_ADMIN_LIST_DATA_NAME.TYPE],
  [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]: d[GDS_ADMIN_LIST_DATA_NAME.SORT_ID],
  [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: d[GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER],
});
