import React, { useState } from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import InputAdornment from "@mui/material/InputAdornment";

import { ButtonPayment } from "./button";
import { text, TextComponent } from "../../../lib/common/text";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";

export const PaymentInfo = ({
  data,
  isLoading,
  createTip,
  handleChangePrice,
  price,
}) => {
  if (isLoading) {
    return <SkeletonStyled variant="rounded" width={371} height={418} />;
  }

  const firstValuePrice = price && String(price).split("")[0];
  const isDisabled =
    price === "" || Number(price) < 1 || firstValuePrice === "0";

  const boostType = data?.metadata?.static?.boostType;
  const coachingType = data?.metadata?.static?.coachingType;
  const current = data?.metadata?.static?.current;
  const desired = data?.metadata?.static?.desired;
  const additionals = data?.metadata?.static?.additionals;

  const title = data?.metadata?.static?.title;
  const additionalsString = additionals && additionals.join(", ");
  const isAdditionals = additionals && additionals.length > 0;

  return (
    <Container>
      <TitleStyled>
        <TextComponent
          tid="PAYMENT_INFO.PAYMENT_ID"
          tvalue={{ id: data?.id }}
        />
      </TitleStyled>
      <Divider />
      <TextContainer>
        {title && <Rank tid={title} />}
        {boostType && (
          <Rank>
            <TextComponent
              tid="PAYMENT_INFO.PAYMENT_RANK"
              tvalue={{ boostType, current, desired }}
            />
          </Rank>
        )}
        {coachingType && (
          <Rank>
            <TextComponent tid={coachingType} />
          </Rank>
        )}
        {isAdditionals && (
          <SecondaryText>
            <TextComponent tid={additionalsString} />
          </SecondaryText>
        )}
      </TextContainer>
      <Divider />
      <FieldTextComponent
        name="AMOUNT"
        fullWidth
        type="number"
        onChange={handleChangePrice}
        value={price}
        error={isDisabled}
        errorText={text("PAYMENT_INFO.PAYMENT.ERROR")}
        defaultValue={1}
        InputProps={{
          inputProps: { min: 1 },
          startAdornment: (
            <InputAdornment position="start">{data.valute.icon}</InputAdornment>
          ),
        }}
      />
      <ButtonContainer>
        <ButtonPayment
          disabled={isDisabled}
          type="stripe"
          onClick={() => createTip("stripe")}
        />
        <ButtonPayment
          disabled={isDisabled}
          type="nowpayments"
          onClick={() => createTip("nowpayments")}
        />
      </ButtonContainer>
    </Container>
  );
};

const SkeletonStyled = styled(Skeleton)`
  z-index: 20;
  border-radius: 12px !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: start;
  text-align: start;
`;

const SecondaryText = styled.span`
  color: #fff;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;

  @media screen and (max-width: 720px) {
    font-size: 14px;
  }
`;

const Rank = styled.span`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5em;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;

const TitleStyled = styled.span`
  color: #fff;

  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1em;

  @media screen and (max-width: 720px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  z-index: 10;

  width: 100%;

  @media screen and (max-width: 720px) {
    padding: 24px 20px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background: #fff;
`;
