import {
  NOTICE_DATA_TYPE,
  NOTICE_TYPE_TYPE,
  NOTICE_TYPE_VALUE,
  VISUAL_NOTICE_TYPE_TYPE,
} from "./notice.type";

import { convertDatetime } from "../convert/convert.core";

import { text } from "../text";
import { chooseNoticeCallbackByType } from "./notice.core";
import { convertTimezone } from "../user/user.convert";
import { convertPurchaseStatus } from "../purchase/purchase.convert";
import { convertBoostRank } from "../boost/boost.convert";
import { convertPaymentSystemType } from "../payment/payment.convert";

export const convertNoticeType = (notice, noticeTypeId, data) => {
  if (!noticeTypeId) {
    return {
      id: null,
      value: null,
      text: null,
    };
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.USER_TIMEZONE_CHANGE) {
    data.timezone = convertTimezone(data.timezone).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PURCHASE_DONE_FOR_BOOSTER) {
    data.purchaseStatus = convertPurchaseStatus(data.purchaseStatus).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PURCHASE_DONE_FOR_CUSTOMER) {
    data.purchaseStatus = convertPurchaseStatus(data.purchaseStatus).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PURCHASE_UPDATE_STATUS_FOR_CUSTOMER) {
    data.purchaseStatus = convertPurchaseStatus(data.purchaseStatus).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.BOOST_UPDATE_ACTUAL_RANK_FOR_CUSTOMER) {
    data.boostActualRank = convertBoostRank(data.boostActualRank).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.BOOST_UPDATE_ACTUAL_RANK_FOR_BOOSTER) {
    data.boostActualRank = convertBoostRank(data.boostActualRank).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PAYMENT_CREATE_FOR_ADMIN) {
    data.paymentSystem = convertPaymentSystemType(data.paymentSystem).text;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PAYMENT_TIP_CREATE_FOR_ADMIN) {
    data.paymentSystem = convertPaymentSystemType(data.paymentSystem).text;
  }

  return {
    id: noticeTypeId,
    value: NOTICE_TYPE_VALUE[noticeTypeId],
    text: text(`NOTICE.DATA.TYPE.${NOTICE_TYPE_VALUE[noticeTypeId]}`, {
      ...data,
    }),
  };
};

export const convertVisualNoticeType = (noticeTypeId) => {
  if (!noticeTypeId) {
    return VISUAL_NOTICE_TYPE_TYPE.DEFAULT;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PAYMENT_CREATE_FOR_ADMIN) {
    return VISUAL_NOTICE_TYPE_TYPE.PAYMENT_CREATE;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PAYMENT_TIP_CREATE_FOR_ADMIN) {
    return VISUAL_NOTICE_TYPE_TYPE.PAYMENT_CREATE;
  }

  if (noticeTypeId === NOTICE_TYPE_TYPE.PURCHASE_ADDED_LIKE_BOOSTER) {
    return VISUAL_NOTICE_TYPE_TYPE.PURCHASE_ADDED;
  }

  return VISUAL_NOTICE_TYPE_TYPE.DEFAULT;
};

export const convertNoticeItem = (data) => {
  if (!data) {
    return null;
  }

  const noticeType = convertNoticeType(
    data,
    data[NOTICE_DATA_TYPE.TYPE],
    data[NOTICE_DATA_TYPE.DATA]
  );

  const [action, link] = chooseNoticeCallbackByType(
    noticeType.id,
    data[NOTICE_DATA_TYPE.DATA]
  );

  return {
    [NOTICE_DATA_TYPE.ID]: data[NOTICE_DATA_TYPE.ID],
    [NOTICE_DATA_TYPE.DATA]: data[NOTICE_DATA_TYPE.DATA],
    [NOTICE_DATA_TYPE.ACTIVE]: data[NOTICE_DATA_TYPE.ACTIVE],
    [NOTICE_DATA_TYPE.VIEW]: data[NOTICE_DATA_TYPE.VIEW],
    [NOTICE_DATA_TYPE.CREATE_DATE]: convertDatetime(
      data[NOTICE_DATA_TYPE.CREATE_DATE]
    ),
    [NOTICE_DATA_TYPE.HOUR]: data[NOTICE_DATA_TYPE.HOUR] || null,
    [NOTICE_DATA_TYPE.TYPE]: noticeType,
    [NOTICE_DATA_TYPE.TEXT]: noticeType.text,
    [NOTICE_DATA_TYPE.ACTION]: action,
    [NOTICE_DATA_TYPE.LINK]: link,
    [NOTICE_DATA_TYPE.VISUAL]: convertVisualNoticeType(noticeType.id),
    [NOTICE_DATA_TYPE.USER]: data[NOTICE_DATA_TYPE.USER]
      ? {
          [NOTICE_DATA_TYPE.USER_LOGIN]:
            data[NOTICE_DATA_TYPE.USER][NOTICE_DATA_TYPE.USER_LOGIN],
          [NOTICE_DATA_TYPE.USER_ID]:
            data[NOTICE_DATA_TYPE.USER][NOTICE_DATA_TYPE.USER_ID],
        }
      : null,
  };
};

export const convertNoticeList = (list) => list.map(convertNoticeItem);
