import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { text } from "../text";

export const FieldTextComponent = ({
  errorText,
  error,
  label,
  placeholder,
  ...props
}) => {
  return (
    <Grid container spacing={2}>
      {label && (
        <Grid item>
          <Typography variant="fieldLabel">{label}</Typography>
        </Grid>
      )}
      <Grid item>
        <TextField
          placeholder={placeholder ? text(placeholder) : ""}
          error={error}
          {...props}
        />
      </Grid>
      {error && errorText && (
        <Grid item>
          <Typography variant="errorText">{errorText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
