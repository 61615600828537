import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PURCHASE_ADMIN_ITEM_STORE_NAME } from "../purchase-admin-item/purchase-admin-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { TabContainer } from "../../lib/common/tab/tab.container";
import { TabSkeletonComponent } from "../../lib/common/tab/tab-skeleton.component";
import {
  getPurchaseAdminItem,
  getPurchaseAdminItemCoaching,
  getPurchaseAdminItemGds,
} from "./purchase-admin-item-tab.constant";
import { getRequestData } from "../../main/store/store.service";

export function PurchaseAdminItemTabContainer() {
  let { purchaseId } = useParams();
  const { state } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
  }));

  const PURCHASE_ADMIN_ITEM_TAB_BOOST = getPurchaseAdminItem(purchaseId);
  const PURCHASE_ADMIN_ITEM_TAB_GDS = getPurchaseAdminItemGds(purchaseId);
  const PURCHASE_ADMIN_ITEM_TAB_COACHING =
    getPurchaseAdminItemCoaching(purchaseId);

  const stateData = getRequestData(state.request);

  const type = stateData?.type?.id;

  if (type === PURCHASE_TYPE_TYPE.BOOST) {
    return <TabContainer tab={PURCHASE_ADMIN_ITEM_TAB_BOOST} />;
  }

  if (type === PURCHASE_TYPE_TYPE.GDS) {
    return <TabContainer tab={PURCHASE_ADMIN_ITEM_TAB_GDS} />;
  }

  if (type === PURCHASE_TYPE_TYPE.COACHING) {
    return <TabContainer tab={PURCHASE_ADMIN_ITEM_TAB_COACHING} />;
  }

  return <TabSkeletonComponent />;
}
