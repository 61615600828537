import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import {
  FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME,
  FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC,
} from "../finder-gds-item-admin-list.constant";

export function FinderGdsItemAdminListViewComponent(props) {
  const {
    data: { list: data },
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="FINDER.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (gdsItemId, gdsIdItem) => () => {
    console.log("gdsItemId", gdsItemId);
    console.log("gdsIdItem", gdsIdItem);

    navigate(
      FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC({
        gdsItemId,
        gdsId: gdsIdItem,
      })
    );
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem
          onClick={handleClickItem(item?.id, item?.gds?.id)}
          button
          variant="list"
        >
          <ListItemText
            primary={
              <TextComponent variant="body1">
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.ID"
                  tvalue={{
                    id: item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID],
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.ORDER"
                  tvalue={{
                    order: item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.ORDER],
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.BOUGHT"
                  tvalue={{
                    bought: String(
                      item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]
                    ),
                  }}
                />
                <br />
                <TextComponent
                  tid="GDS_ITEM.ADMIN.LIST.LIST.STATUS"
                  tvalue={{
                    status:
                      item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS].text,
                  }}
                />
              </TextComponent>
            }
            secondary={
              (item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.PRICE_EN] ||
                item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.PRICE_RU]) && (
                <TextComponent variant="body1">
                  <TextComponent
                    tid="GDS_ITEM.ADMIN.LIST.LIST.PRICE_RU"
                    tvalue={{
                      priceRu:
                        item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.PRICE_RU],
                    }}
                  />
                  <br />
                  <TextComponent
                    tid="GDS_ITEM.ADMIN.LIST.LIST.PRICE_EN"
                    tvalue={{
                      priceEn:
                        item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.PRICE_EN],
                    }}
                  />
                  <br />
                  <TextComponent
                    tid="GDS_ITEM.ADMIN.LIST.LIST.STOCK_RU"
                    tvalue={{
                      stockRu:
                        item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STOCK_RU],
                    }}
                  />
                  <br />
                  <TextComponent
                    tid="GDS_ITEM.ADMIN.LIST.LIST.STOCK_EN"
                    tvalue={{
                      stockEn:
                        item[FINDER_GDS_ITEM_ADMIN_LIST_DATA_NAME.STOCK_EN],
                    }}
                  />
                </TextComponent>
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
