import { httpRequest } from "../../main/http";

import {
  GDS_ADMIN_LIST_API,
  GDS_ADMIN_LIST_ACTION_TYPE,
  GDS_ADMIN_LIST_DATA_NAME,
  GDS_ADMIN_LIST_STORE_NAME,
} from "./gds-admin-list.constant";

import { convertGdsAdminList } from "./gds-admin-list.convert";
import { getRequestData } from "../../main/store/store.service";

export function getGdsAdminList(data) {
  return async (dispatch, getState) => {
    const stateData = getRequestData(
      getState()[GDS_ADMIN_LIST_STORE_NAME].request
    );

    const params = data
      ? {
          [GDS_ADMIN_LIST_DATA_NAME.SKIP]: data[GDS_ADMIN_LIST_DATA_NAME.SKIP],
          [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
            data[GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER],
          [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]:
            data[GDS_ADMIN_LIST_DATA_NAME.SORT_ID],
          [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: data[
            GDS_ADMIN_LIST_DATA_NAME.CATEGORY
          ]
            ? data[GDS_ADMIN_LIST_DATA_NAME.CATEGORY]
            : "",

          [GDS_ADMIN_LIST_DATA_NAME.STATUS]:
            data[GDS_ADMIN_LIST_DATA_NAME.STATUS],
          [GDS_ADMIN_LIST_DATA_NAME.TYPE]: data[GDS_ADMIN_LIST_DATA_NAME.TYPE],
          [GDS_ADMIN_LIST_DATA_NAME.HAS_RU]:
            data[GDS_ADMIN_LIST_DATA_NAME.HAS_RU],
        }
      : {
          [GDS_ADMIN_LIST_DATA_NAME.SKIP]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.SKIP],
          [GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.SORT_ORDER],
          [GDS_ADMIN_LIST_DATA_NAME.SORT_ID]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.SORT_ID],
          [GDS_ADMIN_LIST_DATA_NAME.CATEGORY]: stateData[
            GDS_ADMIN_LIST_DATA_NAME.CATEGORY
          ]
            ? stateData[GDS_ADMIN_LIST_DATA_NAME.CATEGORY]
            : "",
          [GDS_ADMIN_LIST_DATA_NAME.STATUS]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.STATUS],
          [GDS_ADMIN_LIST_DATA_NAME.TYPE]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.TYPE],
          [GDS_ADMIN_LIST_DATA_NAME.HAS_RU]:
            stateData[GDS_ADMIN_LIST_DATA_NAME.HAS_RU],
        };

    dispatch({
      type: GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ADMIN_LIST_API.GDS_ADMIN_LIST.TYPE,
        url: GDS_ADMIN_LIST_API.GDS_ADMIN_LIST.ENDPOINT,
        params,
      });

      dispatch({
        type: GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertGdsAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
