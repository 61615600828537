import { text } from "../text/text.core";

import {
  PAYMENT_VALUTE_TYPE,
  PAYMENT_VALUTE_VALUE,
  PAYMENT_SYSTEM_VALUE,
  PAYMENT_SYSTEM_TYPE,
  PAYMENT_VALUTE_ICON,
} from "./payment.type";

export const convertPaymentValuteType = (paymentValuteId) => {
  if (!paymentValuteId) {
    return null;
  }

  return {
    id: paymentValuteId,
    value: PAYMENT_VALUTE_VALUE[paymentValuteId],
    text: text(`PAYMENT.DATA.VALUTE.${PAYMENT_VALUTE_VALUE[paymentValuteId]}`),
    icon: PAYMENT_VALUTE_ICON[paymentValuteId],
  };
};

export const convertPaymentSystemType = (paymentSystemId) => {
  if (!paymentSystemId) {
    return null;
  }

  return {
    id: paymentSystemId,
    value: PAYMENT_SYSTEM_VALUE[paymentSystemId],
    text: text(`PAYMENT.DATA.SYSTEM.${PAYMENT_SYSTEM_VALUE[paymentSystemId]}`),
  };
};
