import { Navigate } from "react-router-dom";
import { initializeNewWebAudioContext } from "../../../main/audio";
import {
  flashDocumentTitle,
  clearFlashDocumentTitle,
} from "../../../main/flash-title";
import { text } from "../text";
import { NOTICE_TYPE_TYPE, VISUAL_NOTICE_TYPE_TYPE } from "./notice.type";
import { PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-booster-item-boost-account/purchase-booster-item-boost-account.constant";
import { PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-booster-item-boost-time/purchase-booster-item-boost-time.constant";
import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-booster-item-chat/purchase-booster-item-chat.constant";
import { PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-customer-item-chat/purchase-customer-item-chat.constant";
import { PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-booster-item/purchase-booster-item.constant";
import { PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-admin-item/purchase-admin-item.constant";
import { PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-booster-item-coaching-demo/purchase-booster-item-coaching-demo.constant";
import { PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-customer-item-coaching-report/purchase-customer-item-coaching-report.constant";
import { PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC } from "../../../core/purchase-admin-item-chat/purchase-admin-item-chat.constant";
import { TIP_ADMIN_LIST_ROUTE_PATH } from "../../../core/tip-admin-list/tip-admin-list.constant";

export function initVisualNoticePlayer() {
  const webAudioContext = initializeNewWebAudioContext();

  const context = webAudioContext();

  context.loadSound(
    "/static/sound/default.mp3",
    VISUAL_NOTICE_TYPE_TYPE.DEFAULT
  );
  context.loadSound(
    "/static/sound/paymentCreate.mp3",
    VISUAL_NOTICE_TYPE_TYPE.PAYMENT_CREATE
  );
  context.loadSound(
    "/static/sound/purchaseAdded.mp3",
    VISUAL_NOTICE_TYPE_TYPE.PURCHASE_ADDED
  );

  const onErrorSound = new Audio("/static/sound/default.mp3");

  return (tid, tvalue, type = VISUAL_NOTICE_TYPE_TYPE.DEFAULT) => {
    try {
      flashDocumentTitle(text(tid, tvalue));

      if (typeof document === "undefined") return null;

      const width = document ? document.body.clientWidth || 1200 : 1200;

      if (width < 1200) return null;

      context.playSound(type);

      console.log("notice play sound one");
    } catch {
      onErrorSound.play();
      console.log("notice play sound one on error");
    }
  };
}

export const clearVisualNotice = () => {
  clearFlashDocumentTitle();
};

export function chooseNoticeCallbackByType(typeId, data, redirect) {
  switch (Number(typeId)) {
    case NOTICE_TYPE_TYPE.CHAT_MESSAGE_BOOSTER_NO_ANSWER:
      return [
        () =>
          redirect(
            PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.CHAT_MESSAGE_BOOSTER_LONG_ANSWER:
      return [
        () =>
          redirect(
            PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_ACCOUNT_ADD_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_ACCOUNT_DELETE_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_TIME_ADD_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_TIME_DELETE_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    // case NOTICE_TYPE_TYPE.USER_CREATE:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_VERIFICATION:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_CONFIRMED:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_RECOVERY:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_RESTORED:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_EMAIL_CHANGE:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_DATA_CHANGE:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_PASSWORD_CHANGE:
    //   return null;
    // case NOTICE_TYPE_TYPE.USER_TIMEZONE_CHANGE:
    //   return null;
    case NOTICE_TYPE_TYPE.PURCHASE_CREATE:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_NEED_PAY:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_ADMIN:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PURCHASE_ADD_BOOSTER_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PURCHASE_UPDATE_BOOSTER_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_TIP_CREATE_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];

    case NOTICE_TYPE_TYPE.PURCHASE_ADDED_LIKE_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    // case NOTICE_TYPE_TYPE.PURCHASE_REMOVED_LIKE_BOOSTER:
    //   return null;
    // case NOTICE_TYPE_TYPE.PURCHASE_REMOVED_LIKE_CUSTOMER:
    //   return null;
    case NOTICE_TYPE_TYPE.PURCHASE_GET_GDS_ITEM:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PURCHASE_DONE_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    // case NOTICE_TYPE_TYPE.PURCHASE_DONE_FOR_BOOSTER:
    //   return null;
    case NOTICE_TYPE_TYPE.PURCHASE_UPDATE_STATUS_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_CREATE:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_UPDATE_ACTUAL_RANK_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.BOOST_UPDATE_ACTUAL_RANK_FOR_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_TIP_CREATE_FOR_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_CREATE_FOR_ADMIN:
      return [
        () =>
          redirect(
            PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.PAYMENT_TIP_CREATE_FOR_ADMIN:
      return [
        () => redirect(TIP_ADMIN_LIST_ROUTE_PATH),
        TIP_ADMIN_LIST_ROUTE_PATH,
      ];
    case NOTICE_TYPE_TYPE.COACHING_DEMO_ADD_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.COACHING_DEMO_DELETE_BY_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.COACHING_REPORT_ADD_BY_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.COACHING_REPORT_DELETE_BY_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.COACHING_UPDATE_ACTUAL_SESSION_FOR_CUSTOMER:
      return [
        () =>
          redirect(
            PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    case NOTICE_TYPE_TYPE.COACHING_UPDATE_ACTUAL_SESSION_FOR_BOOSTER:
      return [
        () =>
          redirect(
            PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: data.purchaseId,
            })
          ),
        PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
          purchaseId: data.purchaseId,
        }),
      ];
    default:
      return [null, null];
  }
}
