import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";

import { SkeletonDataViewComponent } from "../../lib/common/skeleton-data-view/skeleton-data-view.component";
import { TextComponent } from "../../lib/common/text";
import { PurchaseCustomerItemChatListViewComponent } from "./frame/purchase-customer-item-chat-list-view.component";
import { BoosterOnlineIndicatorElement } from "./frame/booster-online-indicator";
import { TipItemCreateUserContainer } from "../tip-customer-item-create/tip-customer-item-create.container";

export function PurchaseCustomerItemChatListComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    data,
    errorMessage,
    purchaseData,
    isUpdatePending,
  } = props;

  return (
    <Box>
      <Paper sx={{ p: 6, pb: 0 }}>
        <Box>
          <Box
            sx={{
              pb: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.CUSTOMER.CHAT.LIST.TITLE"
            />
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
              <BoosterOnlineIndicatorElement purchaseData={purchaseData} />
              <TipItemCreateUserContainer purchaseData={purchaseData} />
            </Box>
          </Box>

          <Divider />
          {isSuccess && (
            <PurchaseCustomerItemChatListViewComponent data={data} />
          )}
          {isPending && (
            <Box sx={{ pt: 4, pb: 6 }}>
              <SkeletonDataViewComponent />
            </Box>
          )}

          {isError && (
            <Box sx={{ pt: 4, pb: 6 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}

          {/* {isUpdatePending && <LoaderBarComponent />} */}
          {/* {isPending && <LoaderBarComponent />} */}
        </Box>
      </Paper>
    </Box>
  );
}
