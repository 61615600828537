import Grid from "@mui/material/Grid";
import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME } from "../purchase-admin-item-boost-account-data.constant";

export function PurchaseAdminItemBoostAccountDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PURCHASE.ADMIN.ITEM_BOOST_ACCOUNT.DATA.VIEW.DATA_NAME"
        />
        <TextComponent sx={{ whiteSpace: "break-spaces" }} variant="body1">
          {data[PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA] || (
            <TextComponent tid="PURCHASE.ADMIN.ITEM_BOOST_ACCOUNT.DATA.VIEW.DATA_NONE" />
          )}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
