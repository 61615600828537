import { convertDateToCalendarTimePm } from "../../lib/common/convert/convert.core";
import { FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME } from "./finder-chat-gds-item-admin-list.constant";

export const convertGdsItemAdminList = (d) => ({
  [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.LIST]: d.list.map((data) => ({
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID]: data.id,
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.TYPE]: data.type,
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.USER]: data.user,
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.VIEW]: data.view,
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.DATA]: data.data,
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.CREATE_DATE]:
      convertDateToCalendarTimePm(
        data[FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.CREATE_DATE]
      ),
    [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.PURCHASE]: {
      [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID]: data.purchase?.id ?? null,
      [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS_ITEM]:
        data.purchase?.gdsItem ?? null,
      [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.GDS]:
        data.purchase?.gds ?? null,
      [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOOST]: data.purchase?.boost
        ? {
            [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.ID]:
              data.purchase.boost.id,
          }
        : null,
      [FINDER_CHAT_GDS_ITEM_ADMIN_LIST_DATA_NAME.BOOST_ACCOUNT]:
        data.purchase?.boostAccount ?? null,
    },
  })),
});
