import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { TipAdminListPaginationComponent } from "./tip-admin-list-pagination.component";
import { TIP_ADMIN_LIST_STORE_NAME } from "../tip-admin-list/tip-admin-list.constant";
import {
  getTipAdminList,
  updateTipAdminList,
} from "../tip-admin-list/tip-admin-list.action";
import { getRequestData } from "../../main/store/store.service";
import { TIP_ADMIN_LIST_PAGINATION_CRON_UPDATE } from "./tip-admin-list-pagination.constant";

export function TipAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: getRequestData(state[TIP_ADMIN_LIST_STORE_NAME].request),
  }));

  const [intervalId, setIntervalId] = React.useState(null);

  const onChangeFilter = (data) => {
    dispatch(getTipAdminList({ ...state, ...data }));

    clearInterval(intervalId);

    const intId = setInterval(() => {
      dispatch(updateTipAdminList());
    }, TIP_ADMIN_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);
  };

  React.useEffect(() => {
    clearInterval(intervalId);

    dispatch(getTipAdminList());

    const intId = setInterval(() => {
      dispatch(updateTipAdminList());
    }, TIP_ADMIN_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <TipAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
    />
  );
}
