import { httpRequest } from "../../main/http";

import {
  NAVIGATION_UPDATE_ONLINE_DATA_API,
  NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE,
} from "./navigation-online-item.constant";

export function uploadBoosterStatusForm({ online }) {
  return async (dispatch) => {
    dispatch({
      type: NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: NAVIGATION_UPDATE_ONLINE_DATA_API.CHANGE_DATA.TYPE,
        url: NAVIGATION_UPDATE_ONLINE_DATA_API.CHANGE_DATA.ENDPOINT(online),
      });

      dispatch({
        type: NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export async function getBoosterStatus() {
  try {
    const res = await httpRequest({
      method: NAVIGATION_UPDATE_ONLINE_DATA_API.NAVIGATION_ONLINE_STATUS.TYPE,
      url: NAVIGATION_UPDATE_ONLINE_DATA_API.NAVIGATION_ONLINE_STATUS.ENDPOINT,
    });

    return { success: true, data: res.data };
  } catch (error) {
    console.error(error);

    if (error.response) {
      return { success: false, errorMessage: error.response.data.message };
    } else {
      return { success: false, errorMessage: "Network error" };
    }
  }
}
