import React from "react";
import styled from "styled-components";

import { TextComponent } from "../../../lib/common/text/text.component";

export function BoosterOnlineIndicatorElement({ purchaseData }) {
  const boosterStatus = purchaseData?.booster?.online;

  const ONLINE_THRESHOLD = 20 * 60 * 1000;

  const checkOnlineStatus = (date) => {
    if (!date) return false;
    return new Date() - new Date(date) <= ONLINE_THRESHOLD;
  };

  const isOnline = checkOnlineStatus(boosterStatus);

  return (
    <Container>
      <Indicator active={isOnline} />
      <TextComponent
        variant="body1"
        component="div"
        tid="PURCHASE.CHAT.BOOSTER_STATUS"
        tvalue={{
          status: isOnline ? "online" : "offline",
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? "green" : "red")};
`;
