import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { FieldSelectComponent } from "../../../lib/common/field-select/field-select.component";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { GDS_ADMIN_CREATE_DATA_NAME } from "../gds-admin-create.constant";
import { Field } from "formik";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { FieldFileContainer } from "../../../lib/common/field-file/field-file.container";
import {
  GDS_CATEGORY_GROUPED_OPTION,
  GDS_CATEGORY_OPTION,
  GDS_TAG_OPTION,
  GDS_TYPE_OPTION,
} from "../../../lib/common/gds/gds.type";
import { FieldSelectGroupComponent } from "../../../lib/common/field-select-group/field-select-group.component";
import { useState } from "react";

export const GdsAdminCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  // Додано стан для HAS_RU
  const [hasRu, setHasRu] = useState(values[GDS_ADMIN_CREATE_DATA_NAME.HAS_RU]);

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  const hanldePreviewChange = (v) => {
    setFieldValue(v);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.ORDER" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.ORDER}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.ORDER]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.ORDER)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.ORDER)}
            />
          </Grid>
          <Grid item>
            {/* <FieldSelectComponent
              option={GDS_CATEGORY_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.CATEGORY" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.CATEGORY}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
            /> */}
            <FieldSelectGroupComponent
              multiple={true}
              option={GDS_CATEGORY_GROUPED_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.CATEGORY" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.CATEGORY}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.CATEGORY]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.CATEGORY)}
            />
          </Grid>
          {/* <Grid item>
            <FieldSelectComponent
              option={GDS_TYPE_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TYPE" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TYPE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TYPE]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TYPE)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TYPE)}
            />
          </Grid> */}
          <Grid item>
            <FieldSelectComponent
              option={GDS_TAG_OPTION}
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TAG" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TAG}
              onChange={handleChange}
              multiple
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TAG]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TAG)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TAG)}
            />
          </Grid>
          <Grid item>
            <Field name={GDS_ADMIN_CREATE_DATA_NAME.HAS_VERIFY}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  }
                  label={
                    <TextComponent tid="GDS.ADMIN.CREATE.FORM.HAS_VERIFY" />
                  }
                />
              )}
            </Field>
          </Grid>

          <Grid item>
            <Field name={GDS_ADMIN_CREATE_DATA_NAME.HAS_RU}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setHasRu((prev) => !prev);
                      }}
                    />
                  }
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.HAS_RU" />}
                />
              )}
            </Field>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.PRICE_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE_EN)}
            />
          </Grid>
          {/* <Grid item>
            <FieldTextComponent
              type="number"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.STOCK_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.STOCK_EN)}
            />
          </Grid> */}

          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TITLE_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE_EN)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="GDS.ADMIN.CREATE.FORM.DESCRIPTION_EN" />
              }
              name={GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN)}
              fullWidth
              multiline
              rows={1}
              errorText={getFieldError(
                GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.BODY_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.BODY_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              multiline
              rows={4}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.BODY_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY_EN)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY_EN)}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.URL_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.URL_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.URL_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL_EN)}
              fullWidth
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL_EN)}
            />
          </Grid>
          <Grid item>
            <FieldFileContainer
              label={
                <TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_PREVIEW_EN" />
              }
              name={GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN)}
              errorText={getFieldError(
                GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_EN
              )}
            />
          </Grid>
          <Grid item>
            <FieldFileContainer
              label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_LIST_EN" />}
              name={GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN]}
              error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN)}
              errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_EN)}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {/* =============== */}
          {hasRu && (
            <>
              <Grid item>
                <FieldTextComponent
                  type="number"
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.PRICE_RU" />}
                  name={GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU]}
                  error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU)}
                  fullWidth
                  errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.PRICE_RU)}
                />
              </Grid>
              <Grid item>
                <FieldTextComponent
                  type="text"
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.TITLE_RU" />}
                  name={GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU]}
                  error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU)}
                  fullWidth
                  errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.TITLE_RU)}
                />
              </Grid>
              <Grid item>
                <FieldTextComponent
                  type="text"
                  label={
                    <TextComponent tid="GDS.ADMIN.CREATE.FORM.DESCRIPTION_RU" />
                  }
                  name={GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={1}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU]}
                  error={isFieldError(
                    GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU
                  )}
                  fullWidth
                  errorText={getFieldError(
                    GDS_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU
                  )}
                />
              </Grid>
              <Grid item>
                <FieldTextComponent
                  type="text"
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.BODY_RU" />}
                  name={GDS_ADMIN_CREATE_DATA_NAME.BODY_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={4}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.BODY_RU]}
                  error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY_RU)}
                  fullWidth
                  errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.BODY_RU)}
                />
              </Grid>
              <Grid item>
                <FieldTextComponent
                  type="text"
                  label={<TextComponent tid="GDS.ADMIN.CREATE.FORM.URL_RU" />}
                  name={GDS_ADMIN_CREATE_DATA_NAME.URL_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.URL_RU]}
                  error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL_RU)}
                  fullWidth
                  errorText={getFieldError(GDS_ADMIN_CREATE_DATA_NAME.URL_RU)}
                />
              </Grid>
              <Grid item>
                <FieldFileContainer
                  label={
                    <TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_PREVIEW_RU" />
                  }
                  name={GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU]}
                  error={isFieldError(
                    GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU
                  )}
                  errorText={getFieldError(
                    GDS_ADMIN_CREATE_DATA_NAME.FILE_PREVIEW_RU
                  )}
                />
              </Grid>
              <Grid item>
                <FieldFileContainer
                  label={
                    <TextComponent tid="GDS.ADMIN.CREATE.FORM.FILE_LIST_RU" />
                  }
                  name={GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU]}
                  error={isFieldError(GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU)}
                  errorText={getFieldError(
                    GDS_ADMIN_CREATE_DATA_NAME.FILE_LIST_RU
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="GDS.ADMIN.CREATE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
