import { USER_ADMIN_ITEM_DATA_DATA_NAME } from "./user-admin-item-data.constant";

import {
  convertBonusHistory,
  convertBoosterRoleList,
  convertTimezone,
  convertUserRole,
} from "../../lib/common/user/user.convert";
import { convertTime } from "../../lib/common/convert/convert.core";

export const convertUserAdminItemData = (data) => {
  if (!data) {
    return;
  }

  const ONLINE_THRESHOLD = 20 * 60 * 1000;

  const checkOnlineStatus = (date) => {
    if (!date) return false;
    return new Date() - new Date(date) <= ONLINE_THRESHOLD;
  };

  const isOnline = checkOnlineStatus(
    data[USER_ADMIN_ITEM_DATA_DATA_NAME.ONLINE]
  );

  return {
    [USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS_HISTORY]: convertBonusHistory(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS_HISTORY]
    ),

    [USER_ADMIN_ITEM_DATA_DATA_NAME.ONLINE]: isOnline
      ? "online"
      : convertTime(data[USER_ADMIN_ITEM_DATA_DATA_NAME.ONLINE]),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.BOOSTER_ROLE]: convertBoosterRoleList(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.BOOSTER_ROLE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.ID]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.ID],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE]: convertTimezone(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE]: convertUserRole(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.TELEGRAM]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.TELEGRAM] || null,
  };
};
