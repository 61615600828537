import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";

import { TextComponent } from "../text/text.component";
import styled from "styled-components";

export const FieldSelectGroupComponent = ({
  errorText,
  error,
  label,
  option,
  name,
  setFieldValue,
  onChange,
  value,
  onBlur,
  multiple,
  size,
  defaultValue,
  ...props
}) => {
  if (defaultValue) {
    value = defaultValue;
  }

  // Ініціалізація значення
  const [valueData, setValue] = React.useState(
    value
      ? multiple
        ? value.map((v) => {
            // Шукаємо елемент у кожній групі
            for (let group of option) {
              const foundItem = group.items.find(
                (item) => Number(item.value) === Number(v.id)
              );
              if (foundItem) return foundItem;
            }
            return null;
          })
        : option
            .flatMap((group) => group.items) // Розгортаємо групи в один масив
            .find((i) => Number(i.value) === Number(value.id)) // Знаходимо елемент
      : multiple
        ? []
        : null
  );

  const handleChange = (e) => {
    e.target.name = name;
    onChange(e);
    setValue(e.target.value); // Оновлюємо стан значень
  };

  const handleSubheaderClick = (group) => {
    // Перевіряємо, чи є група вже вибраною
    const isGroupSelected = valueData.some((item) =>
      group.items.includes(item)
    );

    // Оновлюємо значення в залежності від того, чи вибрана група
    const newValue = isGroupSelected
      ? valueData.filter((item) => !group.items.includes(item)) // Знімаємо вибрані елементи
      : [...valueData, ...group.items]; // Додаємо елементи групи

    setValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  const withGroupedOptions = (options) => {
    return options.map((group) => [
      <StyledListSubheader>
        <ListSubheader
          key={`header-${group.id}`}
          onClick={() => handleSubheaderClick(group)}
          style={{
            cursor: "pointer",
          }}
        >
          <TextComponent tid={group.label} />
        </ListSubheader>
      </StyledListSubheader>,
      group.items.map((item) => (
        <MenuItem key={item.id} value={item}>
          <TextComponent tid={item.tid} variant="selectOption" />
        </MenuItem>
      )),
    ]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="fieldLabel">{label}</Typography>
      </Grid>
      <Grid item>
        <Select
          defaultValue={defaultValue}
          size={size}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              return selected.map((value, i) => (
                <span key={value.id}>
                  {i !== 0 && ", "}
                  <TextComponent
                    component="span"
                    tid={value.tid || value.text}
                    variant="selectOption"
                  />
                </span>
              ));
            } else {
              return (
                <TextComponent
                  component="span"
                  tid={selected?.tid || selected?.text}
                  variant="selectOption"
                />
              );
            }
          }}
          onBlur={onBlur}
          id={name}
          multiple={multiple}
          value={valueData}
          onChange={handleChange}
          {...props}
        >
          {withGroupedOptions(option)}
        </Select>
      </Grid>
      {error && (
        <Grid item>
          <Typography variant="errorText">{errorText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const StyledListSubheader = styled(ListSubheader)`
  cursor: pointer;

  transition:
    background-color 0.3s,
    opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
