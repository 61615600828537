import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { SkeletonListComponent } from "../../lib/common/skeleton-list/skeleton-list.component";
import { TextComponent } from "../../lib/common/text";

import { NoticeAccountListViewComponent } from "./frame/notice-account-list-view.component";

import { NoticeAccountListPaginationContainer } from "../notice-account-list-pagination/notice-account-list-pagination.container";

export function NoticeAccountListComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    data,
    errorMessage,
    viewUpdateId,
    isViewUpdate,
    handleUpdateView,
  } = props;

  return (
    <Box>
      <Paper sx={{ py: 6 }}>
        <Box>
          <Box sx={{ px: 6 }}>
            <Box sx={{ pb: 4 }}>
              <TextComponent
                variant="title"
                component="div"
                tid="NOTICE.ACCOUNT.LIST.TITLE"
              />
            </Box>
            <Divider />
          </Box>
          {isSuccess && (
            <NoticeAccountListViewComponent
              viewUpdateId={viewUpdateId}
              isViewUpdate={isViewUpdate}
              handleUpdateView={handleUpdateView}
              data={data}
            />
          )}
          {isPending && (
            <Box sx={{ px: 6, pt: 4 }}>
              <SkeletonListComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ px: 6, pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {/* {isPending && <LoaderBarComponent />} */}
          <Box sx={{ pt: 4, px: 6 }}>
            <Divider />
            <Box sx={{ pt: 4 }}>
              <NoticeAccountListPaginationContainer />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
