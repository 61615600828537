import React from "react";

import { GdsAdminItemUpdateDataComponent } from "./gds-admin-item-update-data.component";

import { useDispatch, useSelector } from "react-redux";

import { gdsAdminItemUpdateDataFormValidation } from "./gds-admin-item-update-data.validation";

import { convertGdsAdminItemUpdateDataFormData } from "./gds-admin-item-update-data.convert";

import {
  uploadGdsAdminItemUpdateDataForm,
  resetGdsAdminItemUpdateDataFormState,
} from "./gds-admin-item-update-data.action";

import {
  GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from "./gds-admin-item-update-data.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  GDS_ADMIN_ITEM_DATA_STORE_NAME,
  GDS_ADMIN_ITEM_DATA_DATA_NAME,
} from "../gds-admin-item-data/gds-admin-item-data.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, gdsItemStore } = useSelector((state) => ({
    state: state[GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    gdsItemStore: state[GDS_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetGdsAdminItemUpdateDataFormState());
  }, []);

  const gdsAdminItemUpdateDataFormSendData = (values) => {
    const data = convertGdsAdminItemUpdateDataFormData(values);
    dispatch(uploadGdsAdminItemUpdateDataForm(data, gdsItemStore.gdsId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(gdsItemStore.request)) {
      const data = getRequestData(gdsItemStore.request);
      return {
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TAG]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_EN]: [
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_EN],
        ],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_RU] !== null
            ? [data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_RU]]
            : [],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_EN]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_EN],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_RU]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_RU],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.HAS_VERIFY]:
          data[GDS_ADMIN_ITEM_DATA_DATA_NAME.HAS_VERIFY],
        [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.HAS_RU]:
          data[GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.HAS_RU],
      };
    }

    return {};
  };

  return (
    <GdsAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(gdsItemStore.request)}
      initialValue={getInitialValue()}
      validation={gdsAdminItemUpdateDataFormValidation}
      onSubmitForm={gdsAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
