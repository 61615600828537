export const NAVIGATION_FORM = {
  ONLINE: "online",
};

export const NAVIGATION_ONLINE_BOOSTER_STORE_NAME =
  "NAVIGATION_ONLINE_BOOSTER_STORE_NAME";

export const NAVIGATION_UPDATE_ONLINE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (online) => `/user-settings/online?status=${online}`,
    TYPE: "POST",
  },
  NAVIGATION_ONLINE_STATUS: {
    ENDPOINT: `user/account/online`,
    TYPE: "GET",
  },
};

export const NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE = {
  FORM_PENDING: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.FORM_RESET",

  REQUEST_PENDING: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_UPDATE_PENDING:
    "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING",
  REQUEST_SUCCESS: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_UPDATE_SUCCESS:
    "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_ERROR: "NAVIGATION_UPDATE_ONLINE_DATA_ACTION_TYPE.REQUEST_ERROR",
};
