export const NOTICE_DATA_TYPE = {
  ID: "id",
  VIEW: "view",
  DATA: "data",
  TYPE: "type",
  CREATE_DATE: "createDate",
  ACTIVE: "active",
  HOUR: "hour",
  USER: "user",
  USER_LOGIN: "login",
  USER_ID: "id",
  TEXT: "text",
  LINK: "link",
  VISUAL: "visual",
  ACTION: "action",
};

export const NOTICE_TYPE_TYPE = {
  USER_CREATE: 1,
  USER_VERIFICATION: 2,
  USER_CONFIRMED: 3,
  USER_RECOVERY: 4,
  USER_RESTORED: 5,
  USER_PASSWORD_CHANGE: 6,
  USER_EMAIL_CHANGE: 7,
  USER_TIMEZONE_CHANGE: 8,
  USER_DATA_CHANGE: 9,
  PURCHASE_CREATE: 10,
  PAYMENT_NEED_PAY: 11,
  CHAT_MESSAGE_CREATE_BY_CUSTOMER: 12,
  CHAT_MESSAGE_CREATE_BY_BOOSTER: 13,
  CHAT_MESSAGE_CREATE_BY_ADMIN: 14,
  PURCHASE_ADD_BOOSTER_FOR_CUSTOMER: 15,
  PURCHASE_ADDED_LIKE_BOOSTER: 16,
  PURCHASE_REMOVED_LIKE_BOOSTER: 17,
  PURCHASE_UPDATE_BOOSTER_FOR_CUSTOMER: 18,
  PURCHASE_REMOVED_LIKE_CUSTOMER: 19,
  PURCHASE_GET_GDS_ITEM: 20,
  PURCHASE_DONE_FOR_BOOSTER: 21,
  PURCHASE_DONE_FOR_CUSTOMER: 22,
  PURCHASE_UPDATE_STATUS_FOR_CUSTOMER: 23,
  PAYMENT_CREATE: 24,
  BOOST_UPDATE_ACTUAL_RANK_FOR_CUSTOMER: 25,
  BOOST_TIME_ADD_BY_CUSTOMER: 26,
  BOOST_TIME_DELETE_BY_CUSTOMER: 27,
  BOOST_ACCOUNT_ADD_BY_CUSTOMER: 28,
  BOOST_ACCOUNT_DELETE_BY_CUSTOMER: 29,
  BOOST_UPDATE_ACTUAL_RANK_FOR_BOOSTER: 30,
  PAYMENT_CREATE_FOR_ADMIN: 31,
  COACHING_DEMO_ADD_BY_CUSTOMER: 32,
  COACHING_DEMO_DELETE_BY_CUSTOMER: 33,
  COACHING_REPORT_ADD_BY_BOOSTER: 34,
  COACHING_REPORT_DELETE_BY_BOOSTER: 35,
  COACHING_UPDATE_ACTUAL_SESSION_FOR_CUSTOMER: 36,
  COACHING_UPDATE_ACTUAL_SESSION_FOR_BOOSTER: 37,
  CHAT_MESSAGE_BOOSTER_LONG_ANSWER: 38,
  CHAT_MESSAGE_BOOSTER_NO_ANSWER: 39,
  BOOSTER_NEW_TASK_ORDER_FEED: 40,
  PAYMENT_TIP_CREATE_FOR_ADMIN: 41,
  PAYMENT_TIP_CREATE_FOR_CUSTOMER: 42,
  PAYMENT_TIP_CREATE_FOR_BOOSTER: 43,
};

export const NOTICE_TYPE_VALUE = {
  1: "USER_CREATE",
  2: "USER_VERIFICATION",
  3: "USER_CONFIRMED",
  4: "USER_RECOVERY",
  5: "USER_RESTORED",
  6: "USER_PASSWORD_CHANGE",
  7: "USER_EMAIL_CHANGE",
  8: "USER_TIMEZONE_CHANGE",
  9: "USER_DATA_CHANGE",
  10: "PURCHASE_CREATE",
  11: "PAYMENT_NEED_PAY",
  12: "CHAT_MESSAGE_CREATE_BY_CUSTOMER",
  13: "CHAT_MESSAGE_CREATE_BY_BOOSTER",
  14: "CHAT_MESSAGE_CREATE_BY_ADMIN",
  15: "PURCHASE_ADD_BOOSTER_FOR_CUSTOMER",
  16: "PURCHASE_ADDED_LIKE_BOOSTER",
  17: "PURCHASE_REMOVED_LIKE_BOOSTER",
  18: "PURCHASE_UPDATE_BOOSTER_FOR_CUSTOMER",
  19: "PURCHASE_REMOVED_LIKE_CUSTOMER",
  20: "PURCHASE_GET_GDS_ITEM",
  21: "PURCHASE_DONE_FOR_BOOSTER",
  22: "PURCHASE_DONE_FOR_CUSTOMER",
  23: "PURCHASE_UPDATE_STATUS_FOR_CUSTOMER",
  24: "PAYMENT_CREATE",
  25: "BOOST_UPDATE_ACTUAL_RANK_FOR_CUSTOMER",
  26: "BOOST_TIME_ADD_BY_CUSTOMER",
  27: "BOOST_TIME_DELETE_BY_CUSTOMER",
  28: "BOOST_ACCOUNT_ADD_BY_CUSTOMER",
  29: "BOOST_ACCOUNT_DELETE_BY_CUSTOMER",
  30: "BOOST_UPDATE_ACTUAL_RANK_FOR_BOOSTER",
  31: "PAYMENT_CREATE_FOR_ADMIN",
  32: "COACHING_DEMO_ADD_BY_CUSTOMER",
  33: "COACHING_DEMO_DELETE_BY_CUSTOMER",
  34: "COACHING_REPORT_ADD_BY_BOOSTER",
  35: "COACHING_REPORT_DELETE_BY_BOOSTER",
  36: "COACHING_UPDATE_ACTUAL_SESSION_FOR_CUSTOMER",
  37: "COACHING_UPDATE_ACTUAL_SESSION_FOR_BOOSTER",
  38: "CHAT_MESSAGE_BOOSTER_LONG_ANSWER",
  39: "CHAT_MESSAGE_BOOSTER_NO_ANSWER",
  40: "BOOSTER_NEW_TASK_ORDER_FEED",
  41: "PAYMENT_TIP_CREATE_FOR_ADMIN",
  42: "PAYMENT_TIP_CREATE_FOR_CUSTOMER",
  43: "PAYMENT_TIP_CREATE_FOR_BOOSTER",
};

export const VISUAL_NOTICE_TYPE_TYPE = {
  DEFAULT: 1,
  PAYMENT_CREATE: 2,
  PURCHASE_ADDED: 3,
};
