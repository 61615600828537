import { markdown } from "markdown";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { CHAT_MESSAGE_TYPE_TYPE } from "../../../lib/common/chat/chat.type";
import { TextComponent } from "../../../lib/common/text";
import { FileListComponent } from "../../../lib/common/file-list/file-list.component";

export const CardViewComponent = ({ data: msg }) => {
  console.log("msg", msg);
  return (
    <Grid container sx={{ pt: 4 }} spacing={4}>
      <Grid item>
        <Grid
          spacing={3}
          container
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <Grid item xs={1}>
            {msg.type === CHAT_MESSAGE_TYPE_TYPE.BOOSTER && (
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#2952ff" }}
              >
                E
              </Avatar>
            )}
            {msg.type === CHAT_MESSAGE_TYPE_TYPE.CUSTOMER && (
              <Avatar sx={{ width: 32, height: 32, backgroundColor: "green" }}>
                C
              </Avatar>
            )}
            {msg.type === CHAT_MESSAGE_TYPE_TYPE.ADMIN && (
              <Avatar sx={{ width: 32, height: 32, backgroundColor: "red" }}>
                A
              </Avatar>
            )}
          </Grid>
          <Grid sx={{ width: "100%" }} item xs={11}>
            <Grid container spacing={2}>
              <Grid item>
                {msg.type === CHAT_MESSAGE_TYPE_TYPE.BOOSTER && (
                  <TextComponent
                    variant="chatMessageTitle"
                    sx={{ color: "#2952ff" }}
                    tid={
                      msg?.user?.login || "PURCHASE.CHAT.MESSAGE.BOOSTER_LOGIN"
                    }
                  />
                )}
                {msg.type === CHAT_MESSAGE_TYPE_TYPE.CUSTOMER && (
                  <TextComponent
                    variant="chatMessageTitle"
                    sx={{ color: "green" }}
                    tid="PURCHASE.CHAT.MESSAGE.CUSTOMER_LOGIN"
                  />
                )}
                {msg.type === CHAT_MESSAGE_TYPE_TYPE.ADMIN && (
                  <TextComponent
                    variant="chatMessageTitle"
                    sx={{ color: "red" }}
                    tid="PURCHASE.CHAT.MESSAGE.ADMIN_LOGIN"
                  />
                )}
              </Grid>
              <Grid item>
                {msg.type === CHAT_MESSAGE_TYPE_TYPE.BOOSTER && (
                  <TextComponent
                    sx={{ whiteSpace: "break-spaces", lineHeight: "1.4em" }}
                    variant="chatMessageData"
                  >
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{
                        __html: markdown.toHTML(msg.data),
                      }}
                    />
                  </TextComponent>
                )}
                {msg.type === CHAT_MESSAGE_TYPE_TYPE.CUSTOMER && (
                  <TextComponent
                    sx={{ whiteSpace: "break-spaces", lineHeight: "1.4em" }}
                    variant="chatMessageData"
                  >
                    {msg.data}
                  </TextComponent>
                )}

                {msg.type === CHAT_MESSAGE_TYPE_TYPE.ADMIN && (
                  <TextComponent
                    variant="chatMessageData"
                    sx={{
                      color: "#FFDADA",
                      whiteSpace: "break-spaces",
                      lineHeight: "1.4em",
                    }}
                  >
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{
                        __html: markdown.toHTML(msg.data),
                      }}
                    />
                  </TextComponent>
                )}
              </Grid>

              {msg.file && (
                <Grid item>
                  <FileListComponent item={msg.file} />
                </Grid>
              )}

              <Grid item>
                <Grid
                  container
                  spacing={2}
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <TextComponent variant="chatMessageTime">
                      {msg.createDate}
                    </TextComponent>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "4px !important" }}>
                    <TextComponent variant="chatMessageTime">|</TextComponent>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "4px !important" }}>
                    <TextComponent
                      tid={`id${msg.id}`}
                      variant="chatMessageTime"
                    />
                  </Grid>
                  <Grid item sx={{ paddingLeft: "4px !important" }}>
                    <TextComponent variant="chatMessageTime">|</TextComponent>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "4px !important" }}>
                    <TextComponent
                      tid={`purchase ${msg.purchase?.id}`}
                      variant="chatMessageTime"
                    />
                  </Grid>

                  {msg.type === CHAT_MESSAGE_TYPE_TYPE.CUSTOMER && (
                    <Grid item>
                      <TextComponent variant="chatMessageData">
                        {msg.view ? (
                          <DoneAllIcon sx={{ fontSize: 14 }} />
                        ) : (
                          <DoneIcon sx={{ fontSize: 14, opacity: 0.5 }} />
                        )}
                      </TextComponent>
                    </Grid>
                  )}
                  {msg.type === CHAT_MESSAGE_TYPE_TYPE.BOOSTER && (
                    <Grid item>
                      <TextComponent variant="chatMessageData">
                        {msg.view ? (
                          <DoneAllIcon sx={{ fontSize: 14 }} />
                        ) : (
                          <DoneIcon sx={{ fontSize: 14, opacity: 0.5 }} />
                        )}
                      </TextComponent>
                    </Grid>
                  )}
                  {msg.type === CHAT_MESSAGE_TYPE_TYPE.ADMIN && (
                    <Grid item>
                      <TextComponent variant="chatMessageData">
                        {msg.view ? (
                          <DoneAllIcon sx={{ fontSize: 14 }} />
                        ) : (
                          <DoneIcon sx={{ fontSize: 14, opacity: 0.5 }} />
                        )}
                      </TextComponent>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
