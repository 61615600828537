import { format } from "date-fns";
import { text } from "../text/text.core";

import {
  USER_ROLE_VALUE,
  BOOSTER_ROLE_VALUE,
  BOOSTER_ROLE_TID,
} from "./user.type";

export const convertUserRole = (userRoleId) => {
  if (!userRoleId) {
    return null;
  }

  return {
    id: userRoleId,
    value: USER_ROLE_VALUE[userRoleId],
    text: text(`USER.DATA.ROLE.${USER_ROLE_VALUE[userRoleId]}`),
  };
};

export const convertBonusHistory = (data) => {
  if (!data || data.length === 0) return [];

  return data.map(([bonus, date, purchaseId, userId]) => ({
    bonus,
    date: format(new Date(date), "MM.dd.yyyy HH:mm"),
    purchaseId: purchaseId || null,
    userId: userId || null,
  }));
};

export const convertBoosterRole = (userRoleId) => {
  if (!userRoleId) {
    return null;
  }

  return {
    id: userRoleId,
    value: USER_ROLE_VALUE[userRoleId],
    text: text(`USER.DATA.BOOST.ROLE.${BOOSTER_ROLE_VALUE[userRoleId]}`),
  };
};

export const convertBoosterRoleList = (list) => {
  if (!list) {
    return [];
  }

  if (list.length === 0) {
    return [];
  }

  return list.map(convertBoosterRole);
};

export const convertTimezone = (timezoneNumber) => {
  if (Number.isNaN(timezoneNumber)) {
    return null;
  }

  return {
    id: timezoneNumber,
    value: timezoneNumber,
    text: text(
      `USER.DATA.TIMEZONE.${String(timezoneNumber).split(".").join("")}`
    ),
  };
};
