import { ACCOUNT_SETTINGS_ROUTE_PATH } from "../account-settings/account-settings.constant";
import { USER_ADMIN_LIST_ROUTE_PATH } from "../user-admin-list/user-admin-list.constant";
import { NEWS_ADMIN_LIST_ROUTE_PATH } from "../news-admin-list/news-admin-list.constant";
import { PROMOCODE_ADMIN_LIST_ROUTE_PATH } from "../promocode-admin-list/promocode-admin-list.constant";
import { POPUP_ADMIN_LIST_ROUTE_PATH } from "../popup-admin-list/popup-admin-list.constant";
import { PURCHASE_ADMIN_LIST_ROUTE_PATH } from "../purchase-admin-list/purchase-admin-list.constant";
import { PURCHASE_BOOSTER_LIST_ROUTE_PATH } from "../purchase-booster-list/purchase-booster-list.constant";
import { PURCHASE_CUSTOMER_LIST_ROUTE_PATH } from "../purchase-customer-list/purchase-customer-list.constant";
import { GDS_ADMIN_LIST_ROUTE_PATH } from "../gds-admin-list/gds-admin-list.constant";

import SettingsIcon from "@mui/icons-material/Settings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ArticleIcon from "@mui/icons-material/Article";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CategoryIcon from "@mui/icons-material/Category";
import WorkIcon from "@mui/icons-material/Work";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from "../notice-account-list/notice-account-list.constant";
import { NOTICE_ADMIN_LIST_ROUTE_PATH } from "../notice-admin-list/notice-admin-list.constant";
import { FILE_ADMIN_ROUTE_PATH } from "../file-admin/file-admin.constant";
import { PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH } from "../purchase-booster-order-list/purchase-booster-order-list.constant";
import { TIP_ADMIN_LIST_ROUTE_PATH } from "../tip-admin-list/tip-admin-list.constant";
import { FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH } from "../finder-gds-item-admin-list/finder-gds-item-admin-list.constant";

export const NAVIGATION_ADMIN_ROUTE_LIST = [
  {
    id: 6,
    route: PURCHASE_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.PURCHASE",
    path: PURCHASE_ADMIN_LIST_ROUTE_PATH,
    icon: <ShoppingCartIcon />,
  },
  {
    id: 8,
    route: TIP_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.TIP",
    path: TIP_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 9,
    route: FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.FINDER",
    path: FINDER_GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 7,
    route: GDS_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.GDS",
    path: GDS_ADMIN_LIST_ROUTE_PATH,
    icon: <CategoryIcon />,
  },
  {
    id: 2,
    route: USER_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.USER",
    path: USER_ADMIN_LIST_ROUTE_PATH,
    icon: <PeopleAltIcon />,
  },
  {
    id: 3,
    route: NEWS_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NEWS",
    path: NEWS_ADMIN_LIST_ROUTE_PATH,
    icon: <ArticleIcon />,
  },
  {
    id: 4,
    route: PROMOCODE_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.PROMOCODE",
    path: PROMOCODE_ADMIN_LIST_ROUTE_PATH,
    icon: <LocalOfferIcon />,
  },
  {
    id: 5,
    route: POPUP_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.POPUP",
    path: POPUP_ADMIN_LIST_ROUTE_PATH,
    icon: <FeedbackIcon />,
  },
  {
    id: 4,
    route: FILE_ADMIN_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.FILE",
    path: FILE_ADMIN_ROUTE_PATH,
    icon: <InsertDriveFileIcon />,
  },
  {
    id: 6,
    route: NOTICE_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.ADMIN_NOTICE",
    path: NOTICE_ADMIN_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 1,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon />,
  },
];

export const NAVIGATION_USER_ROUTE_LIST = [
  {
    id: 1,
    route: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.CUSTOMER_PURCHASE",
    path: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
    icon: <ShoppingBasketIcon sx={{ fontSize: 28 }} />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon sx={{ fontSize: 28 }} />,
  },
  {
    id: 2,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon sx={{ fontSize: 28 }} />,
  },
];

export const NAVIGATION_BOOSTER_ROUTE_LIST = [
  {
    id: 1,
    route: PURCHASE_BOOSTER_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.BOOSTER_WORK",
    path: PURCHASE_BOOSTER_LIST_ROUTE_PATH,
    icon: <WorkIcon />,
  },
  {
    id: 16,
    route: PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.BOOSTER_ORDER",
    path: PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH,
    icon: <ShoppingCartIcon />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 2,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon />,
  },
];

export const NAVIGATION_EDITOR_ROUTE_LIST = [
  {
    id: 6,
    route: GDS_ADMIN_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.GDS",
    path: GDS_ADMIN_LIST_ROUTE_PATH,
    icon: <CategoryIcon />,
  },
  {
    id: 13,
    route: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.NOTICE",
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
    icon: <NotificationsIcon />,
  },
  {
    id: 2,
    route: ACCOUNT_SETTINGS_ROUTE_PATH,
    tid: "NAVIGATION.ROUTE.SETTINGS",
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
    icon: <SettingsIcon />,
  },
];
