export const TIP_ITEM_UPDATE_ADMIN_STORE_NAME = "TIP_ITEM_UPDATE_ADMIN";

export const TIP_ITEM_UPDATE_ADMIN_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (tip) => `/tip/${tip}/admin`,
    TYPE: "PATCH",
  },
};

export const TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE = {
  FORM_PENDING: "TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "TIP_ITEM_UPDATE_ADMIN_ACTION_TYPE.FORM_RESET",
};

export const TIP_ITEM_UPDATE_ADMIN_DATA = {
  ID: "id",
};
