import React from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import { TIP_ADMIN_LIST_DATA_NAME } from "../tip-admin-list/tip-admin-list.constant";

export function TipAdminListPaginationComponent(props) {
  const { onChangeFilter, data } = props;

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [TIP_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid item>
          <Pagination
            count={paginationCount}
            page={paginationPage}
            onChange={handleChangePagination}
            size="large"
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Paper>
    </Box>
  );
}
